import { IGenericItem, IInitialGenericState } from 'interfaces/generics.interface';

const initialCatalogGenericState: IInitialGenericState = {
  generics: {
    family: [] as IGenericItem[],
    color: [] as IGenericItem[],
    gender: [] as IGenericItem[],
    season: [] as IGenericItem[],
    segmentation: [] as IGenericItem[],
    division: [] as IGenericItem[],
  },
  getGenerics: () => Promise.resolve(),
  isLoadingGenerics: false,
  addGenerics: () => Promise.resolve(''),
  updateGeneric: () => Promise.resolve(false),
};

export default initialCatalogGenericState;
