import {
  IinitialCatalogCorrelationsState,
  TCatalogCorrelationDataSource,
} from 'interfaces/catalogCorrelations.interface';

const initialCatalogCorrelationsState: IinitialCatalogCorrelationsState = {
  correlations: {
    family: [] as TCatalogCorrelationDataSource[],
    color: [] as TCatalogCorrelationDataSource[],
    gender: [] as TCatalogCorrelationDataSource[],
    season: [] as TCatalogCorrelationDataSource[],
    segmentation: [] as TCatalogCorrelationDataSource[],
    division: [] as TCatalogCorrelationDataSource[],
  },
  isLoading: false,
  getCatalogCorrelations: () => {},
  assignGenerics: () => {},
  isLoadingAssignGenerics: false,
};

export default initialCatalogCorrelationsState;
