import { TResponseAPI } from 'interfaces/general.interface';
import { basePath } from './utils/config';
import { TPointsOfSaleToUpdateRequest } from 'interfaces/clients.interface';
import * as clientType from './responseInterfaces/client.response.interface';

export const getAssociationsAPI = async (
  tenantId: string,
  token: string
): Promise<TResponseAPI & clientType.TGetAssociationsResponseAPI> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/brand_profile/get_association_requests?tenant_id=${tenantId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getBrandPointsOfSaleAPI = async (
  aliasId: string,
  token: string
): Promise<TResponseAPI & clientType.TGetBrandPointsOfSaleAPI> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/brand_profile/get_alias_points_of_sale?client_alias_id=${aliasId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getClientProfileAPI = async (
  clientId: string,
  token: string
): Promise<TResponseAPI & clientType.TGetClientProfileResponseAPI> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/client_profile/get_client_profile?client_id=${clientId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

// export const getClientPointOfSaleProfileAPI = async (pointOfSaleId: string, token: string): Promise<TResponseAPI & TGetClientPointOfSaleProfileAPI> => {
//   const params = {
//     method: "GET",
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//   }
//   const response = await fetch(`${basePath}/point_of_sale_profile/get_point_of_sale_profile?point_of_sale_id=${pointOfSaleId}`, params)
//   const data = await response.json()
//   return { response, data }
// }

export const getClientPointsOfSaleAPI = async (
  clientId: string,
  token: string
): Promise<TResponseAPI & clientType.TGetClientPointsOfSaleResponseAPI> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/client_profile/get_client_points_of_sale?client_id=${clientId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const createClientAliasAPI = async (client: any, token: string): Promise<any> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(client),
  };
  const response = await fetch(`${basePath}/brand_profile/create_client_alias`, params);
  const data = await response.json();
  return { response, data };
};

export const updateClientAPI = async (
  clientId: string,
  client: any,
  token: string
): Promise<any> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_alias_id: clientId,
      update: client,
    }),
  };
  const response = await fetch(`${basePath}/brand_profile/update_client_alias`, params);
  const data = await response.json();
  return { response, data };
};

export const getTiersAPI = async (brandId: string, token: string): Promise<any> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/association/get_tiers?brand_id=${brandId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getSegmentationsAPI = async (
  brandId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/association/get_segmentations?brand_id=${brandId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const createPointOfSaleAPI = async (body: any, token: string): Promise<any> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/brand_profile/create_point_of_sale`, params);
  const data = await response.json();
  return { response, data };
};

// export const updatePointOfSaleAPI = async (body: any, token: string): Promise<any> => {
//   const params = {
//     method: "PATCH",
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(body)
//   }
//   const response = await fetch(`${basePath}/association/update_brand_point_of_sale`, params)
//   const data = await response.json()
//   return { response, data }
// }

export const updatePointOfSaleAPI = async (body: any, token: string): Promise<any> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/brand_profile/update_point_of_sale`, params);
  const data = await response.json();
  return { response, data };
};

export const removePointOfSaleAPI = async (
  pointOfSaleId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      points_of_sale_id: [pointOfSaleId],
    }),
  };
  const response = await fetch(`${basePath}/brand_profile/delete_point_of_sale`, params);
  const data = await response.json();
  return { response, data };
};

export const associateClientAPI = async (
  body: any,
  tenantId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(
    `${basePath}/brand_profile/associate?tenant_id=${tenantId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const detachAssociationAPI = async (
  associationId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/brand_profile/delete_association_with_client?association_id=${associationId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

type TGetPointOfSaleCategoriesAPI = {
  data: [
    {
      id: string;
      name: string;
      online: boolean;
      createdAt: string;
      updatedAt: string;
    },
  ];
};
export const getPointsOfSaleCategoriesAPI = async (
  token: string
): Promise<TResponseAPI & TGetPointOfSaleCategoriesAPI> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/point_of_sale_profile/get_point_of_sale_category`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getPointsOfSaleAssociationsAPI = async (
  clientAliasId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/brand_profile/get_point_of_sale_associations?client_alias_id=${clientAliasId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const associatePointsOfSaleAPI = async (
  psToAssociate: TPointsOfSaleToUpdateRequest[],
  aliasId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      alias_id: aliasId,
      items: psToAssociate,
    }),
  };
  const response = await fetch(
    `${basePath}/brand_profile/associate_point_of_sale`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const detachPointOfSaleAPI = async (
  pointOfSaleId: string,
  clientAliasId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/brand_profile/delete_point_of_sale_association?point_of_sale_id=${pointOfSaleId}&client_alias_id=${clientAliasId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getClientContactsAPI = async (
  clientId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/client_profile/get_client_contacts?client_id=${clientId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getExchangesAPI = async (token: string): Promise<any> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/point_of_sale_profile/get_exchange_list`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const getBrandSegmentationsAPI = async (
  brandId: string,
  token: string
): Promise<any> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePath}/segmentation/get_segmentations?brand_id=${brandId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const searchClientsAPI = async (
  body: clientType.TsearchClientBody,
  signal: AbortSignal,
  token: string
): Promise<clientType.TsearchClientsAPIResponse> => {
  const params = {
    method: 'POST',
    signal,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/client/search`, params);
  const data = await response.json();
  return { response, data };
};
