import { useReducer, useMemo, useEffect, memo } from 'react';
import CountriesContext from './CountriesContext';
import initialCountriesState from './initialCountriesState';
import countriesReducer from 'contexts/UI/countries/reducer/countries.reducer';
import * as action from 'contexts/UI/countries/reducer/countries.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { ChildrenProps } from 'types';

function CountriesProvider(props: ChildrenProps) {
  const [countriesState, dispatch] = useReducer(countriesReducer, initialCountriesState);
  const { getAccessTokenSilently } = useAuth0();
  const { t: translate } = useTranslation();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      await action.getCountriesAction(dispatch, token, translate);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoProvider = useMemo(
    () => ({
      ...countriesState,
    }),
    [countriesState]
  );

  return (
    <CountriesContext.Provider value={memoProvider}>
      {props.children}
    </CountriesContext.Provider>
  );
}

export default memo(CountriesProvider);
