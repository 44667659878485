import * as types from './catalogDownload.types';
import {
  IBodyFilterCSVState,
  ICatalogFetchCSVDataPlainSkuResponse,
  ICatalogFetchCSVDataResponse,
  IInitialCatalogDownloadState,
  TCatalogDownloadFiltersSelected,
  TImageSelectionTypes,
  imageSelectionTypes,
} from 'interfaces/catalogDownload.interface';
import { catalogDownloadFiltersKeys } from '../CatalogDownloadProvider';
import { abortCatalogController } from 'utils/abortController';
import { getCatalog, getFiltersAPI } from 'api/catalog.api';
import { sizeOrder } from 'contexts/catalog/reducer/catalog.actions';
import { ICompanyState, IDbUserState } from 'interfaces/user.interface';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment';
import pLimit from 'p-limit';
import { messageInfo, messageSuccess } from 'views/components/UI/message';
import { MutableRefObject } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { IColumnsSelectOrderState } from 'views/pages/main/catalog/catalogDownloadPage/components/columnsSelectOrder/ColumnsSelectOrder';

type TapplyFilterChangesAction = {
  dispatch: any;
  company: ICompanyState;
  value: TCatalogDownloadFiltersSelected;
  catalogDownloadState: IInitialCatalogDownloadState;
  dbUserLanguageId: string;
  translate: any;
  token: string;
};
export async function applyFilterChangesAction({
  dispatch,
  company,
  value,
  catalogDownloadState,
  dbUserLanguageId,
  translate,
  token,
}: TapplyFilterChangesAction) {
  dispatch({ type: types.SET_SELECTED_VALUES, payload: [] });
  catalogDownloadState.catalogBodyFetch.options.search = [];
  dispatch({ type: types.SET_QUERY_SEARCH, payload: '' });
  catalogDownloadState.filtersSelectedTemp.brand = [
    {
      label: company.brand.name,
      value: company.brand.id,
      values: [company.brand.id],
    },
  ];
  // if (!catalogDownloadState.filtersSelectedTemp.brand || catalogDownloadState.filtersSelectedTemp.brand.length === 0) {
  //   dispatch({
  //     type: types.SET_FILTERS_ALERT, payload: {
  //       description: '',
  //       message: translate('catalog_download_alert_brand-required'),
  //       show: true,
  //       type: 'warning'
  //     }
  //   })
  //   setTimeout(() => {
  //     dispatch({
  //       type: types.SET_FILTERS_ALERT, payload: {
  //         description: '',
  //         message: '',
  //         show: false,
  //         type: 'info'
  //       }
  //     })
  //   }, 5000)
  // }

  dispatch({ type: types.SET_FILTERS_SELECTED, payload: value });
  // Generate tags
  if (Object.keys(value).length === 0) return;
  let stringValues: string[] = [];
  const mapInputs = Object.keys(value).map((key) => {
    return { key, value: value[key as keyof typeof value] };
  });
  mapInputs.forEach((input) => {
    console.log(input);
    if (input.key.toLowerCase() === 'brand') return;
    input.value.forEach((value) => {
      stringValues.push(value.label);
    });
  });

  const imageSelectionLabel = () => {
    if (catalogDownloadState.imageSelection === imageSelectionTypes.withImages) {
      return translate('catalog_download_columns_with-images');
    } else if (
      catalogDownloadState.imageSelection === imageSelectionTypes.withoutImages
    ) {
      return translate('catalog_download_columns_without-images');
    } else {
      return translate('catalog_download_columns_all-images');
    }
  };

  if (catalogDownloadState.catalogBodyFetch.options.favorite.length > 0) {
    stringValues.push(translate('catalog_download_columns_favorites'));
  } else {
    stringValues = stringValues.filter(
      (item) => item !== translate('catalog_download_columns_favorites')
    );
  }
  if (
    catalogDownloadState.catalogBodyFetch.filter.pvi[0] >= 0 &&
    catalogDownloadState.catalogBodyFetch.filter.pvi[1] > 0
  ) {
    stringValues.push(
      `PVI ${catalogDownloadState.catalogBodyFetch.filter.pvi[0]}€-${catalogDownloadState.catalogBodyFetch.filter.pvi[1]}€`
    );
  } else {
    stringValues = stringValues.filter(
      (item) =>
        item !==
        `PVI ${catalogDownloadState.catalogBodyFetch.filter.pvi[0]}€-${catalogDownloadState.catalogBodyFetch.filter.pvi[1]}€`
    );
  }
  if (
    catalogDownloadState.catalogBodyFetch.filter.pvpr[0] >= 0 &&
    catalogDownloadState.catalogBodyFetch.filter.pvpr[1] > 0
  ) {
    stringValues.push(
      `PVPR ${catalogDownloadState.catalogBodyFetch.filter.pvpr[0]}€-${catalogDownloadState.catalogBodyFetch.filter.pvpr[1]}€`
    );
  } else {
    stringValues = stringValues.filter(
      (item) =>
        item !==
        `PVPR ${catalogDownloadState.catalogBodyFetch.filter.pvpr[0]}€-${catalogDownloadState.catalogBodyFetch.filter.pvpr[1]}€`
    );
  }
  stringValues.push(imageSelectionLabel());
  dispatch({ type: types.SET_FILTERS_TAGS, payload: stringValues ?? [] });

  // Set columns
  const withoutImages =
    catalogDownloadState.imageSelection === imageSelectionTypes.withoutImages;
  dispatch({
    type: types.SET_COLUMNS_ORDER,
    payload: {
      ...catalogDownloadState.columnsOrder,
      images: {
        ...catalogDownloadState.columnsOrder.images,
        show: withoutImages ? false : true,
        required: withoutImages ? false : true,
      },
      color_code: {
        ...catalogDownloadState.columnsOrder.color_code,
        show: catalogDownloadState.columnsOrder.color_code.show,
        required: withoutImages ? false : true,
      },
      reference: {
        ...catalogDownloadState.columnsOrder.reference,
        show: catalogDownloadState.columnsOrder.reference.show,
        required: withoutImages ? false : true,
      },
    },
  });
  dispatch({ type: types.SET_DOWNLOAD_IMAGES, payload: withoutImages ? false : true });

  // Set body
  const inputs = Object.keys(value).map((key) => {
    let values: string[] = [];
    value[key as keyof typeof value].forEach((item) => {
      item.values.forEach((value) => {
        values.push(value);
      });
    });
    return { key, values: values };
  });
  catalogDownloadState.catalogBodyFetch.options.brand_id =
    inputs.find((item) => item.key === catalogDownloadFiltersKeys.brand)?.values || [];
  catalogDownloadState.catalogBodyFetch.options.language_id = dbUserLanguageId;
  catalogDownloadState.catalogBodyFetch.filter.color =
    inputs.find(
      (item) => item.key.toLowerCase() === catalogDownloadFiltersKeys.color.toLowerCase()
    )?.values || [];
  catalogDownloadState.catalogBodyFetch.filter.season =
    inputs.find(
      (item) => item.key.toLowerCase() === catalogDownloadFiltersKeys.season.toLowerCase()
    )?.values || [];
  catalogDownloadState.catalogBodyFetch.filter.division =
    inputs.find(
      (item) =>
        item.key.toLowerCase() === catalogDownloadFiltersKeys.division.toLowerCase()
    )?.values || [];
  catalogDownloadState.catalogBodyFetch.filter.family =
    inputs.find(
      (item) => item.key.toLowerCase() === catalogDownloadFiltersKeys.family.toLowerCase()
    )?.values || [];
  catalogDownloadState.catalogBodyFetch.filter.gender =
    inputs.find(
      (item) => item.key.toLowerCase() === catalogDownloadFiltersKeys.gender.toLowerCase()
    )?.values || [];
  catalogDownloadState.catalogBodyFetch.filter.reference =
    inputs.find(
      (item) =>
        item.key.toLowerCase() === catalogDownloadFiltersKeys.reference.toLowerCase()
    )?.values || [];
  catalogDownloadState.catalogBodyFetch.filter.segmentation =
    inputs.find(
      (item) =>
        item.key.toLowerCase() === catalogDownloadFiltersKeys.segmentation.toLowerCase()
    )?.values || [];

  await getCatalogData({
    dispatch,
    catalogBodyFetch: catalogDownloadState.catalogBodyFetch,
    imageSelection: catalogDownloadState.imageSelection,
    token,
  });
}

type TgetFiltersAction = {
  dispatch: any;
  dbUser: IDbUserState;
  company: ICompanyState;
  // connectedBrands: IMyBrand[],
  token: string;
  translate: any;
};
export async function getFiltersAction({
  dispatch,
  dbUser,
  company,
  // connectedBrands,
  token,
  translate,
}: TgetFiltersAction) {
  dispatch({ type: types.SET_IS_LOADING_FILTERS, payload: true });
  if (!company.brand?.id || !company.country.id || !dbUser.language?.id) return;
  // const brandsMap = connectedBrands.map((brand) => brand.brand.id)
  try {
    if (token) {
      const filtersFetch = await getFiltersAPI(
        company.brand.id,
        company.country.id,
        dbUser.language.id,
        token
      );
      if (filtersFetch.response.status === 200) {
        dispatch({
          type: types.SET_FILTERS,
          payload: filtersFetch.data
            .filter((filter: any) => filter.name.toLowerCase() !== 'brand')
            .filter((filter: any) => filter.name.toLowerCase() !== 'price'),
        });
      } else {
        dispatch({
          type: types.SET_FILTERS_ALERT,
          payload: {
            show: true,
            type: 'error',
            message: translate('catalog_download_alert_get-filters-error'),
            description: '',
          },
        });
      }
    }
  } catch (error) {
    console.log(error);
    dispatch({
      show: true,
      type: 'error',
      message: translate('catalog_download_alert_get-filters-error'),
      description: '',
    });
  } finally {
    dispatch({ type: types.SET_IS_LOADING_FILTERS, payload: false });
  }
}

// COLUMNS ORDER /////////////////////////////////
export async function setColumnsOrderAction({
  dispatch,
  value,
}: {
  dispatch: any;
  value: Record<string, IColumnsSelectOrderState>;
}) {
  dispatch({ type: types.SET_COLUMNS_ORDER, payload: value });
}

export async function moveColumnsOrderAction({
  dispatch,
  result,
  columnsOrder,
}: {
  dispatch: any;
  result: DropResult;
  columnsOrder: Record<string, IColumnsSelectOrderState>;
}) {
  if (!result.destination) return;
  const items = Object.entries(columnsOrder);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);
  const updatedItems = items.map(([key, column], index) => {
    return [key, { ...column, order: index }];
  });
  dispatch({ type: types.SET_COLUMNS_ORDER, payload: Object.fromEntries(updatedItems) });
}

export async function checkColumnAction({
  dispatch,
  columnKey,
  columnsOrder,
}: {
  dispatch: any;
  columnKey: string;
  columnsOrder: Record<string, IColumnsSelectOrderState>;
}) {
  dispatch({
    type: types.SET_COLUMNS_ORDER,
    payload: {
      ...columnsOrder,
      [columnKey]: {
        ...columnsOrder[columnKey],
        show: !columnsOrder[columnKey].show,
      },
    },
  });
}

// SEARCH /////////////////////////////////
export async function applySearchAction({
  dispatch,
  catalogDownloadState,
  translate,
  token,
}: {
  dispatch: any;
  catalogDownloadState: IInitialCatalogDownloadState;
  translate: any;
  token: string;
}) {
  catalogDownloadState.catalogBodyFetch.options.search =
    catalogDownloadState.querySearch.length === 0
      ? []
      : [`%${catalogDownloadState.querySearch.split(' ')}%`];
  await getCatalogData({
    dispatch,
    catalogBodyFetch: catalogDownloadState.catalogBodyFetch,
    imageSelection: catalogDownloadState.imageSelection,
    token,
  });
}

// DOWNLOAD CATALOG /////////////////////////////////
interface IFindItem {
  images: string[] | string;
  [key: string]: any;
}
export async function donwloadCatalogAction({
  dispatch,
  catalogDownloadState,
  completedDownloads,
  translate,
}: {
  dispatch: any;
  catalogDownloadState: IInitialCatalogDownloadState;
  completedDownloads: MutableRefObject<number>;
  translate: any;
}) {
  dispatch({
    type: types.SET_DOWNLOADING_STATUS,
    payload: {
      isDownloading: true,
    },
  });
  try {
    let mountedCSV: any[] = [];
    let imagesToDownloadSet: Set<string> = new Set();

    for (const value of catalogDownloadState.selectedValues) {
      const findItem: IFindItem | undefined = catalogDownloadState.filteredList.find(
        (item) => item.key === (value as number)
      );
      (findItem?.images as string[])?.forEach((image: string) => {
        imagesToDownloadSet.add(image);
      });
      const csv: IFindItem = {
        ...findItem,
        images: (findItem?.images as string[])
          ?.map((image: string) => image.split('/').pop())
          .join(' | ') as string,
      };

      // Create an array of keys sorted by the order
      const keysSortedByOrder = Object.keys(catalogDownloadState.columnsOrder)
        .filter((key) => catalogDownloadState.columnsOrder[key].show) // Only include keys where 'show' is true
        .sort(
          (a, b) =>
            catalogDownloadState.columnsOrder[a].order -
            catalogDownloadState.columnsOrder[b].order
        );

      // Create a new object with keys in the sorted order
      const sortedObj: { [key: string]: any } = {};
      for (const key of keysSortedByOrder) {
        if (key in csv!) {
          sortedObj[key] = csv![key as keyof IFindItem];
        }
      }
      mountedCSV.push(sortedObj);
    }

    // Download the CSV and updates the states
    const csv = Papa.unparse(mountedCSV);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `csv_${moment().format('DD-MM-YYYY-HH:mm:ss')}.csv`);
    if (catalogDownloadState.downloadImages) {
      if (imagesToDownloadSet.size > 50 && imagesToDownloadSet.size < 500) {
        messageInfo(translate('catalog_download_csv-msg-pending-images'));
      }
      if (imagesToDownloadSet.size > 500) {
        messageInfo(translate('catalog_download_csv-msg-pending-images-navigate'), 5);
      }
    } else {
      imagesToDownloadSet.size > 50 &&
        messageSuccess(translate('catalog_download_csv-msg-without-images'));
    }

    // Break if the user doesn't want to download the images
    if (!catalogDownloadState.downloadImages) {
      return messageSuccess(translate('catalog_download_csv-msg-without-images'));
    }
    let imagesToDownload: string[] = Array.from(imagesToDownloadSet);
    if (imagesToDownload.length === 0) {
      return messageSuccess(translate('catalog_download_csv-msg-without-images'));
    }
    dispatch({
      type: types.SET_DOWNLOADING_STATUS,
      payload: {
        totalImagesToDownload: imagesToDownload.length,
      },
    });

    const downloadImage = (url: string, zip: JSZip) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        catalogDownloadState.xhrsRef.push(xhr);
        dispatch({ type: types.SET_XHRS_REF, payload: catalogDownloadState.xhrsRef });
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          if (this.status === 200) {
            const blob = new Blob([this.response], { type: 'image/jpg' });
            zip.file(`${url.split('/').pop()}`, blob, { binary: true });
            completedDownloads.current = completedDownloads.current++;
            dispatch({
              type: types.SET_DOWNLOADING_STATUS,
              payload: {
                completedDownloads: completedDownloads.current++,
              },
            });
            resolve(true);
          } else {
            console.error('Image download failed: ' + this.statusText);
            completedDownloads.current = completedDownloads.current++;
            dispatch({
              type: types.SET_DOWNLOADING_STATUS,
              payload: {
                completedDownloads: completedDownloads.current++,
              },
            });
            resolve(false);
          }
        };
        xhr.onerror = function (err) {
          console.error('There was a network error.', err);
          completedDownloads.current = completedDownloads.current++;
          dispatch({
            type: types.SET_DOWNLOADING_STATUS,
            payload: {
              completedDownloads: completedDownloads.current++,
            },
          });
          resolve(false);
        };
        xhr.send();
      });
    };

    // Divide imagesToDownload in sub -rays of 1000 elements
    const chunkSize = 1000;
    const imageChunks = [];
    for (let i = 0; i < imagesToDownload.length; i += chunkSize) {
      imageChunks.push(imagesToDownload.slice(i, i + chunkSize));
    }

    // For each sub -raray, download the images, buy them in a zip file and download it
    for (let i = 0; i < imageChunks.length; i++) {
      dispatch({ type: types.SET_IS_DOWNLOADING_ZIP, payload: true });
      const zip = new JSZip();
      const limit = pLimit(50); // Limit to 10 concurrent requests
      await Promise.all(
        imageChunks[i].map((url) => limit(() => downloadImage(url, zip)))
      ).then(() => {
        zip.generateAsync({ type: 'blob' }).then((content: any) => {
          saveAs(
            content,
            `images_${i + 1}-${imageChunks.length}_${moment().format('DD-MM-YYYY-HH:mm:ss')}.zip`
          );
          if (i === imageChunks.length - 1) {
            // Check if this is the last zip file
            dispatch({ type: types.SET_IS_DOWNLOADING_ZIP, payload: false });
            if (imagesToDownloadSet.size < 50) {
              messageSuccess(translate('catalog_download_success-msg-imgs-and-csv'));
            } else {
              messageSuccess(translate('catalog_download_success-msg'));
            }
          }
        });
      });
    }
    completedDownloads.current = 0;
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({
      type: types.SET_DOWNLOADING_STATUS,
      payload: {
        isDownloading: false,
        totalImagesToDownload: 0,
        completedDownloads: 0,
      },
    });
    dispatch({ type: types.SET_XHRS_REF, payload: [] });
    completedDownloads.current = 0;
  }
}

export async function cancelDownloadCatalogAction({
  dispatch,
  catalogDownloadState,
  completedDownloads,
}: {
  dispatch: any;
  catalogDownloadState: IInitialCatalogDownloadState;
  completedDownloads: MutableRefObject<number>;
}) {
  if (catalogDownloadState.downloadingStatus.isDownloading) {
    for (const xhr of catalogDownloadState.xhrsRef) {
      xhr.abort();
    }
    dispatch({ type: types.SET_XHRS_REF, payload: [] });
    dispatch({
      type: types.SET_DOWNLOADING_STATUS,
      payload: {
        isDownloading: false,
        totalImagesToDownload: 0,
        completedDownloads: 0,
      },
    });
    dispatch({ type: types.SET_IS_DOWNLOADING_ZIP, payload: false });
    completedDownloads.current = 0;
    return;
  } else {
    dispatch({ type: types.SET_SELECTED_VALUES, payload: [] });
  }
}

export async function setDownloadImagesAction({
  dispatch,
  value,
  columns,
}: {
  dispatch: any;
  value: boolean;
  columns: Record<string, IColumnsSelectOrderState>;
}) {
  dispatch({ type: types.SET_DOWNLOAD_IMAGES, payload: value });
  dispatch({
    type: types.SET_COLUMNS_ORDER,
    payload: {
      ...columns,
      images: {
        ...columns.images,
        show: value === true ? true : columns.images.show,
        required: value,
      },
      color_code: {
        ...columns.color_code,
        show: value === true ? true : columns.color_code.show,
        required: value,
      },
      reference: {
        ...columns.reference,
        show: value === true ? true : columns.reference.show,
        required: value,
      },
    },
  });
}

/////////////////////////////////////////////////////
/////////////////////////////////////////////////////
export async function getCatalogData({
  dispatch,
  catalogBodyFetch,
  imageSelection,
  token,
}: {
  dispatch: React.Dispatch<any>;
  catalogBodyFetch: IBodyFilterCSVState;
  imageSelection: TImageSelectionTypes;
  token: string;
}) {
  let valuesCount = 0;
  let sortValuesCount = 0;
  let values: ICatalogFetchCSVDataPlainSkuResponse[] = [];
  try {
    dispatch({ type: types.SET_IS_LOADING_CATALOG, payload: true });
    const signal = abortCatalogController();
    const catalogFetch = await getCatalog(catalogBodyFetch, token, signal);

    for (const item of catalogFetch.data.data) {
      let typedItem: ICatalogFetchCSVDataResponse & { key: number } = item;
      let productItems: ICatalogFetchCSVDataPlainSkuResponse[] = [];
      for (const sku of typedItem.sku) {
        const newItem: ICatalogFetchCSVDataPlainSkuResponse & { key: number } = {
          key: valuesCount,
          id: typedItem.id,
          brand: typedItem.brand,
          brand_id: typedItem.brand_id,
          ranking: typedItem.ranking,
          units: typedItem.units,
          favorites: typedItem.favorites,
          name: typedItem.name,
          reference: typedItem.reference,
          color: typedItem.color,
          color_code: typedItem.color_code,
          images: typedItem.images,
          season: typedItem.season,
          gender: typedItem.gender,
          division: typedItem.division,
          segmentation: typedItem.segmentation,
          family: typedItem.family,
          material: typedItem.material,
          ean: sku.ean,
          size: sku.size,
          pvi: sku.pvi,
          pvpr: sku.pvpr,
        };
        if (imageSelection === imageSelectionTypes.withImages) {
          if (typedItem.images.length > 0) {
            productItems.push(newItem);
            valuesCount++;
          }
        } else if (imageSelection === imageSelectionTypes.withoutImages) {
          if (!typedItem.images || typedItem.images.length === 0) {
            productItems.push(newItem);
            valuesCount++;
          }
        } else {
          productItems.push(newItem);
          valuesCount++;
        }
      }
      let orderedItems: ICatalogFetchCSVDataPlainSkuResponse[] = [];
      const sortItems = productItems.sort(
        (a, b) =>
          ((sizeOrder as any)[a.size] || Infinity) -
          ((sizeOrder as any)[b.size] || Infinity)
      );
      for (const item of sortItems) {
        item.key = sortValuesCount;
        orderedItems.push(item as ICatalogFetchCSVDataPlainSkuResponse & { key: number });
        sortValuesCount++;
      }
      values.push(...sortItems);
    }
    dispatch({ type: types.SET_FILTERED_LIST, payload: values ?? [] });
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({ type: types.SET_IS_LOADING_CATALOG, payload: false });
    dispatch({ type: types.SET_IS_OPEN_FILTERS, payload: false });
    valuesCount = 0;
    values = [];
    sortValuesCount = 0;
  }
}
