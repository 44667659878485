export const SET_BANNER = 'SET_BANNER';
export const SET_BIO = 'SET_BIO';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const SET_VIDEOS = 'SET_VIDEOS';
export const REMOVE_VIDEO = 'REMOVE_VIDEO';
// Catalog
export const GET_CATALOG = 'GET_CATALOG';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_BODY_PRODUCT_SEARCH = 'SET_BODY_PRODUCT_SEARCH';
export const SET_BODY_PRODUCT_SEARCH_TEMP = 'SET_BODY_PRODUCT_SEARCH_TEMP';
export const INCREMENT_PAGINATION_INDEX = 'INCREMENT_PAGINATION_INDEX';
export const SET_IS_EMPTY_PRODUCTS = 'SET_IS_EMPTY_PRODUCTS';
export const SET_THERE_ARE_MORE_PRODUCTS = 'SET_THERE_ARE_MORE_PRODUCTS';
export const SET_BODY_PRODUCT = 'SET_BODY_PRODUCT';
export const SET_IS_LOADING_MORE_PRODUCTS = 'SET_IS_LOADING_MORE_PRODUCTS';
export const ADD_MORE_TO_CATALOG = 'ADD_MORE_TO_CATALOG';
// Filters
export const ADD_BODY_FILTERS = 'ADD_BODY_FILTERS';
export const REMOVE_BODY_FILTERS = 'REMOVE_BODY_FILTERS';
export const RESET_BODY_FILTERS = 'RESET_BODY_FILTERS';
export const SET_PRICE_SLIDERS = 'SET_PRICE_SLIDERS';
export const SET_FAVORITES = 'SET_FAVORITES';
export const RESET_PRICE_SLIDER = 'RESET_PRICE_SLIDER';
// Search Engine
export const SET_QUERY_SEARCH = 'SET_QUERY_SEARCH';
export const ADD_TO_QUERY_SEARCH_ARRAY = 'ADD_TO_QUERY_SEARCH_ARRAY';
export const REMOVE_FROM_QUERY_SEARCH_ARRAY = 'REMOVE_TO_QUERY_SEARCH_ARRAY';
export const SET_BODY_SEARCH_FILTER = 'SET_BODY_SEARCH_FILTER';
// Sort By
export const SET_SORT_BY = 'SET_SORT_BY';
export const SET_SORT_BY_HAVE_IMAGES = 'SET_SORT_BY_HAVE_IMAGES';
