/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo } from 'react';
import MetaContext from './MetaContext';
import initialMetaState from './initialMetaState';
import metaReducer from 'contexts/meta/reducers/reducer/meta.reducer';
import * as action from 'contexts/meta/reducers/actions/meta.actions';
import * as mapAction from 'contexts/meta/reducers/actions/meta.map.actions';
import * as sftpAction from 'contexts/meta/reducers/actions/meta.sftp.actions';
import * as shopifyAction from 'contexts/meta/reducers/actions/meta.shopify.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useContextUser from 'hooks/contexts/useContextUser';
import { ChildrenProps } from 'interfaces/general.interface';
import * as metaResponseInterface from 'api/responseInterfaces/meta.response.interface';
import * as metaInterface from 'interfaces/meta.interface';
import { TupdateShopifyConnectionAPIBodyUpdateProps } from 'api/responseInterfaces/superAdmin.response.interface';
import useContextSuperAdmin from 'hooks/contexts/useContextSuperAdmin';

function MetaProvider(props: ChildrenProps) {
  const [metaState, dispatch] = useReducer(metaReducer, initialMetaState);
  const { getAccessTokenSilently } = useAuth0();
  const { t: translate } = useTranslation();
  const { company } = useContextUser();
  const { shopify } = useContextSuperAdmin();

  const getConnections = useCallback(async () => {
    if (!company.id) return false;
    const body: metaResponseInterface.TgetConnectorsAPIBodyProps = {
      brand_id: company.brand.id,
      search: [],
      index: 0,
      limit: 1000,
    };
    const token = await getAccessTokenSilently();
    return await action.getConnectionsAction({ dispatch, body, token, translate });
  }, [company.id, getAccessTokenSilently, translate]);

  // MAP //////////////////////////////////////////////////
  const getMaps = useCallback(
    async ({ connectorType, clientId }: metaInterface.IgetMapsProps) => {
      const token = await getAccessTokenSilently();
      return await mapAction.getMapsAction({
        dispatch,
        connectorType,
        clientId,
        token,
        translate,
      });
    },
    []
  );

  const createMap = useCallback(
    async ({ connectorType, body, connection }: metaInterface.ICreateMapProps) => {
      const token = await getAccessTokenSilently();
      return await mapAction.createMapAction({
        dispatch,
        connectorType,
        body,
        connection,
        token,
        translate,
      });
    },
    [translate]
  );

  const updateMap = useCallback(
    async ({ connectorType, body }: metaInterface.IUpdateMapProps) => {
      const token = await getAccessTokenSilently();
      return await mapAction.updateMapAction({
        dispatch,
        connectorType,
        body,
        token,
        translate,
      });
    },
    [translate]
  );

  const addMapToConnection = useCallback(
    async ({
      connectionId,
      mapIds,
      connectorType,
    }: metaInterface.IaddMapToConnectionProps) => {
      const token = await getAccessTokenSilently();
      return await mapAction.addMapToConnectorAction({
        dispatch,
        connectionId,
        mapIds,
        connectorType,
        token,
        translate,
      });
    },
    [getAccessTokenSilently, translate]
  );

  const deleteMapFromConnection = useCallback(
    async ({
      connectionId,
      mapIds,
      connectorType,
    }: metaInterface.IdeleteMapFromConnectionProps) => {
      const token = await getAccessTokenSilently();
      return await mapAction.deleteMapFromConnectorAction({
        dispatch,
        connectionId,
        mapIds,
        connectorType,
        token,
        translate,
      });
    },
    [getAccessTokenSilently, translate]
  );
  /////////////////////////////////////////////////////////

  // SFTP /////////////////////////////////////////////////
  const createSftpConnection = useCallback(
    async (body: metaResponseInterface.TcreateSftpUserAPIBodyProps) => {
      const token = await getAccessTokenSilently();
      return (await sftpAction.createSftpConnectionAction(
        dispatch,
        body,
        token,
        translate
      )) as boolean;
    },
    [translate]
  );

  const removeSftpConnection = useCallback(
    async (user_name: string) => {
      const token = await getAccessTokenSilently();
      sftpAction.deleteSftpConnectionAction(dispatch, user_name, token, translate);
    },
    [translate]
  );
  /////////////////////////////////////////////////////////

  // Shopify //////////////////////////////////////////////
  const createShopifyConnection = useCallback(
    async ({
      body,
    }: {
      body: metaResponseInterface.TcreateShopifyConnectionAPIBodyProps;
    }) => {
      const token = await getAccessTokenSilently();
      return (await shopifyAction.createShopifyConnectionAction({
        dispatch,
        body,
        queries: shopify.queries,
        token,
        translate,
      })) as boolean;
    },
    [shopify.queries]
  );

  const updateShopifyConnection = useCallback(
    async ({
      body,
      connectionId,
    }: {
      body: TupdateShopifyConnectionAPIBodyUpdateProps;
      connectionId: string;
    }) => {
      const token = await getAccessTokenSilently();
      return (await shopifyAction.updateShopifyConnectionAction({
        dispatch,
        body,
        connectionId,
        token,
        translate,
      })) as boolean;
    },
    []
  );

  const removeShopifyConnection = useCallback(
    async ({ connectionId }: { connectionId: string }) => {
      const token = await getAccessTokenSilently();
      return (await shopifyAction.deleteShopifyConnectionAction({
        dispatch,
        connectionId,
        token,
        translate,
      })) as boolean;
    },
    []
  );

  // Configuration Shopify
  const getShopifyConfiguration = useCallback(
    async ({ connectorId }: metaInterface.IgetConfigurationProps) => {
      const token = await getAccessTokenSilently();
      return await shopifyAction.getShopifyConfigurationAction({
        dispatch,
        connectorId,
        token,
        translate,
      });
    },
    []
  );

  const createUpdateShopifyConfiguration = useCallback(
    async ({ connector_id, queries }: metaInterface.IcreateUpdateConfigurationProps) => {
      const token = await getAccessTokenSilently();
      return await shopifyAction.createUpdateShopifyConfigurationAction({
        dispatch,
        connector_id,
        queries,
        configuration: metaState.shopify.configuration,
        token,
        translate,
      });
    },
    [metaState.shopify.configuration]
  );
  /////////////////////////////////////////////////////////

  const memoProvider = useMemo(
    () => ({
      ...metaState,
      getConnections,
      // Maps
      getMaps,
      createMap,
      updateMap,
      addMapToConnection,
      deleteMapFromConnection,
      sftp: {
        ...metaState.sftp,
        createSftpConnection,
        removeSftpConnection,
      },
      shopify: {
        ...metaState.shopify,
        createShopifyConnection,
        updateShopifyConnection,
        removeShopifyConnection,
        // Configuration
        getShopifyConfiguration,
        createUpdateShopifyConfiguration,
      },
    }),
    [
      metaState,
      getConnections,
      // Maps
      getMaps,
      createMap,
      updateMap,
      addMapToConnection,
      deleteMapFromConnection,
      // SFTP
      createSftpConnection,
      removeSftpConnection,
      // Shopify
      createShopifyConnection,
      updateShopifyConnection,
      removeShopifyConnection,
      // Configuration
      getShopifyConfiguration,
      createUpdateShopifyConfiguration,
    ]
  );

  return (
    <MetaContext.Provider value={memoProvider}>{props.children}</MetaContext.Provider>
  );
}

export default memo(MetaProvider);
