import { useContext } from 'react';
import UserContext from 'contexts/user/UserContext';

export default function useContextUser() {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useContextUser must be used within a UserProvider');
  }

  return context;
}
