import {
  IClientState,
  IClientsInitialState,
  IPointOfSaleExchangesState,
  IPointOfSaleState,
  IPointsOfSaleCategoryState,
  TSegmentationsState,
} from 'interfaces/clients.interface';

const initialClientsState: IClientsInitialState = {
  clients: [] as IClientState[],
  allClients: [] as IClientState[],
  clientSelected: {} as IClientState,
  pointsOfSale: [] as IPointOfSaleState[],
  allPointsOfSale: [] as IPointOfSaleState[],
  pointOfSaleSelected: {} as IPointOfSaleState,
  isLoadingClient: true,
  refreshClients: false,
  queryClientsSearch: '',
  queryPointsOfSaleSearch: '',
  pointsOfSaleCategories: [] as IPointsOfSaleCategoryState[],
  exchanges: [] as IPointOfSaleExchangesState[],
  segmentations: {} as TSegmentationsState,
  selectClient: () => {},
  selectPointOfSale: () => {},
  createClient: () => {},
  updateClient: () => {},
  upgradeClientItems: () => Promise.resolve(false),
  isLoadingUpgradeClientItems: false,
  createPointOfSale: () => {},
  updatePointOfSale: () => {},
  removePointOfSale: () => Promise.resolve(false),
  upgradePointsOfSale: () => {},
  shareDataPointOfSale: () => {},
  searchClients: () => {},
  searchPointsOfSale: () => {},
  acceptAssociation: () => {},
  rejectAssociation: () => {},
  associatePointsOfSale: () => {},
  rejectPointOfSale: () => {},
};

export default initialClientsState;
