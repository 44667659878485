/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo } from 'react';
import CatalogCorrelationsContext from './CatalogCorrelationsContext';
import initialCatalogCorrelationsState from './initialCatalogCorrelationsState';
import catalogCorrelationsReducer from './reducer/catalogCorrelations.reducer';
import * as action from './reducer/catalogCorrelations.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useContextUser from 'hooks/contexts/useContextUser';
import { ChildrenProps } from 'types';
import { IGenericType } from 'interfaces/generics.interface';
import { TValuesToAssign } from 'interfaces/catalogCorrelations.interface';

function CatalogCorrelationsProvider(props: ChildrenProps) {
  const [catalogCorrelationsState, dispatch] = useReducer(
    catalogCorrelationsReducer,
    initialCatalogCorrelationsState
  );
  const { getAccessTokenSilently } = useAuth0();
  const { dbUser, company } = useContextUser();
  const { t: translate } = useTranslation();

  const getCatalogCorrelations = useCallback(
    async ({ type }: { type: IGenericType }) => {
      const token = await getAccessTokenSilently();
      if (!company.brand?.id || !dbUser.language?.code) return;
      action.getGenericsAction({
        dispatch,
        brandId: company.brand.id,
        language: dbUser.language.code,
        type,
        token,
        translate,
      });
    },
    [company.brand?.id, dbUser.language?.code]
  );

  const assignGenerics = useCallback(
    async ({ type, values }: { type: IGenericType; values: TValuesToAssign }) => {
      const token = await getAccessTokenSilently();
      action.assignGenericAction({
        dispatch,
        brandId: company.brand.id,
        language: dbUser.language.code,
        type,
        values,
        token,
        translate,
      });
    },
    [company.brand?.id, dbUser.language?.code]
  );

  const memoProvider = useMemo(
    () => ({
      ...catalogCorrelationsState,
      assignGenerics,
      getCatalogCorrelations,
    }),
    [catalogCorrelationsState, assignGenerics, getCatalogCorrelations]
  );

  return (
    <CatalogCorrelationsContext.Provider value={memoProvider}>
      {props.children}
    </CatalogCorrelationsContext.Provider>
  );
}

export default memo(CatalogCorrelationsProvider);
