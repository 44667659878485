import { IInitialCountriesState } from 'interfaces/countries.interface';
import * as CountriesTypes from './countries.types';

export default function countriesReducer(state: IInitialCountriesState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case CountriesTypes.GET_COUNTRIES:
      const getCountries: IInitialCountriesState = {
        ...state,
        countries: payload,
      };
      return getCountries;

    default:
      return state;
  }
}
