import {
  IGenericType,
  TGenericValues,
  TUpdateGenericBody,
} from 'interfaces/generics.interface';
import { basePath } from './utils/config';

export const getGenericsAPI = async (
  option: IGenericType,
  token: string
): Promise<any> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ option: option }),
  };
  const response = await fetch(`${basePath}/generic/get_generics`, params);
  const data = await response.json();
  return { response, data };
};

export const addGenericsAPI = async (
  type: IGenericType,
  values: TGenericValues[],
  token: string
): Promise<any> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      model: type,
      items: values,
    }),
  };
  const response = await fetch(`${basePath}/generic/add_generics`, params);
  const data = await response.json();
  return { response, data };
};

export const updateGenericAPI = async (
  body: TUpdateGenericBody,
  token: string
): Promise<any> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/generic/update_generic`, params);
  const data = await response.json();
  return { response, data };
};
