export const SET_REPORTS_BY_UPLOAD = 'SET_REPORTS_BY_UPLOAD';
export const SET_IS_LOADING_BY_UPLOAD = 'SET_IS_LOADING_BY_UPLOAD';
export const SET_PUBLISH_UPLOAD = 'SET_PUBLISH_UPLOAD';
export const SET_IS_LOADING_PUBLISH_UPLOAD = 'SET_IS_LOADING_PUBLISH_UPLOAD';
export const SET_REJECT_UPLOAD = 'SET_REJECT_UPLOAD';
export const SET_IS_LOADING_REJECT_UPLOAD = 'SET_IS_LOADING_REJECT_UPLOAD';
export const SET_REPORTS_BY_SEASON = 'SET_REPORTS_BY_SEASON';
export const SET_IS_LOADING_BY_SEASON = 'SET_IS_LOADING_BY_SEASON';
export const SET_START_SEASON_DATE = 'SET_START_SEASON_DATE';
export const SET_END_SEASON_DATE = 'SET_END_SEASON_DATE';
export const SET_PUBLISH_SEASON = 'SET_PUBLISH_SEASON';
export const SET_IS_LOADING_ENABLE_SEASON = 'SET_IS_LOADING_ENABLE_SEASON';
export const SET_CSV_SELECTED = 'SET_CSV_SELECTED';
export const SET_IS_LOADING_CSV_SELECTED = 'SET_IS_LOADING_CSV_SELECTED';
export const SET_IS_LOADING_SAVE_CSV = 'SET_IS_LOADING_SAVE_CSV';
export const SET_CSV_MAPPINGS = 'SET_CSV_MAPPINGS';
export const SET_CSV_MAPPING_KEYS = 'SET_CSV_MAPPING_KEYS';
export const SET_IS_LOADING_CSV_MAPPINGS = 'SET_IS_LOADING_CSV_MAPPINGS';
export const DELETE_MAPPING = 'DELETE_MAPPING';
export const CREATE_MAPPING = 'CREATE_MAPPING';
