export interface IMainContactItemState {
  key: string;
  name: string;
  lastname: string;
  email: string;
  phoneNumberPrefix: string;
  phoneNumberSufix: string;
  department: string;
}

const initialMainContactsState = {
  contacts: [] as IMainContactItemState[],
  contactSelected: {} as IMainContactItemState,
  isLoadingContacts: true,
  getMainContacts: () => {},
  selectContact: (contact: any) => {},
  createContact: (contact: any, setIsOpenDrawer: () => void) => {},
  removeContact: (contactId: string) => {},
  updateContact: (newContact: any, setIsOpenDrawer: () => void) => {},
};

export default initialMainContactsState;
