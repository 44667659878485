import { clientProdKey, clientStagingKey } from 'utils/constants';
// Platform Service
const DEV_API = 'https://platform-dev.hub4retail.com';
const STAGING_API = 'https://platform-staging.hub4retail.com';
const PROD_API = 'https://platform-prod.hub4retail.com';
function getBasePath() {
  let url = DEV_API;
  if (process.env.REACT_APP_NODE_ENV === clientProdKey) {
    url = PROD_API;
  } else if (process.env.REACT_APP_NODE_ENV === clientStagingKey) {
    url = STAGING_API;
  }
  return url;
}
export const basePath = getBasePath();
// export const basePath = STAGING_API
// export const basePath = PROD_API

// Upload Service
export const basePathUploadService = 'https://platform-demo.hub4retail.com';

// SFTP Service
export const basePathSftpService = 'https://sftp-api.hub4retail.com';

// Data Service
const DATA_DEV_API = 'https://data-api-dev.hub4retail.com';
const DATA_PROD_API = 'https://data-api-dev.hub4retail.com';
function dataApiBasePath() {
  let url = DATA_DEV_API;
  if (process.env.REACT_APP_NODE_ENV === clientProdKey) {
    url = DATA_PROD_API;
  }
  return url;
}
export const dataBasePath = dataApiBasePath();

export const testToken = '';
