import { IDbUserForm } from 'interfaces/user.interface';
import { basePath } from './utils/config';
import makeRequest from './utils/makeRequest';
import { TResponseAPI } from 'interfaces/general.interface';
import { TUpdateUserApiResponse } from './responseInterfaces/user.response.interface';

// TESTED
export const loginDbUserAPI = async (token: string): Promise<any> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${basePath}/user/login/brand`, params);
  const data = await response.json();
  return { response, data };
};

export const updateDbUserAPI = async (
  body: { filter: { id: string }; update: IDbUserForm },
  token: string
): Promise<TResponseAPI & TUpdateUserApiResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/user/update_user`, params);
  const data = await response.json();
  return { response, data };
};

export const updateUserAvatarAPI = async (
  userId: string,
  image: File,
  token: any
): Promise<any> => {
  const url = `${basePath}/upload/brand_user_profile_image?user_id=${userId} `;
  const formData = new FormData();
  formData.append('image', image);
  return makeRequest(url, true, true, 'POST', token, formData, '');
};

export const updateCompanyLogoAPI = async (
  tenantId: string,
  image: File,
  token: any
): Promise<any> => {
  const url = `${basePath}/upload/brand_logo?tenant_id=${tenantId} `;
  const formData = new FormData();
  formData.append('image', image);
  return makeRequest(url, true, true, 'POST', token, formData, '');
};

export const updateUserLanguageAPI = async (
  body: any,
  token: string
): Promise<TResponseAPI & TUpdateUserApiResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/user/update_user`, params);
  const data = await response.json();
  return { response, data };
};

export const verifyEmailAPI = async (
  body: { filter: { id: string }; update: { enabled: boolean } },
  token: string
): Promise<TResponseAPI & TUpdateUserApiResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/user/update_user`, params);
  const data = await response.json();
  return { response, data };
};

export const acceptTermsAPI = async (
  body: { filter: { id: string }; update: { signed: boolean } },
  token: string
): Promise<TResponseAPI & TUpdateUserApiResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/user/update_user`, params);
  const data = await response.json();
  console.log(data);

  return { response, data };
};

export const changePasswordAPI = async (
  auth0Id: any,
  newPsw: string,
  token: string
): Promise<TResponseAPI & TUpdateUserApiResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filter: { id: auth0Id },
      update: { password: newPsw },
    }),
  };
  const response = await fetch(`${basePath}/user/update_user_password`, params);
  const data = await response.json();
  return { response, data };
};

// NOT TESTED
export const deleteAuth0UserAPI = async (body: any, token: string): Promise<any> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePath}/auth/delete_user`, params);
  const data = await response.json();
  return data;
};
