import { useState, useCallback, useMemo, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageContext from './LanguageContext';
import useContextUser from 'hooks/contexts/useContextUser';
import { ChildrenProps } from 'types';
import { getLanguagesAPI } from 'api/general.api';
import { useAuth0 } from '@auth0/auth0-react';
import { TLanguageResponseItem } from 'api/responseInterfaces/general.response.interface';

export type TLangType = 'en-gb' | 'it' | 'fr' | 'es';
export const langs = {
  es: 'es',
  en: 'en-gb',
  fr: 'fr',
  it: 'it',
};

function LanguageProvider({ children }: ChildrenProps) {
  const { i18n } = useTranslation();
  const { dbUser } = useContextUser();
  const [language, setLanguage] = useState('es' as TLangType);
  const [languages, setLanguages] = useState([] as TLanguageResponseItem[]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const languagesFetch = await getLanguagesAPI(token);
        if (languagesFetch.response.status === 200) {
          setLanguages(languagesFetch.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLanguageAction = useCallback(
    async (language: TLangType) => {
      document.documentElement.setAttribute('lang', language);
      setLanguage(language);
      i18n.changeLanguage(language);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dbUser.id]
  );

  const memorizedProviderLanguage = useMemo(
    () => ({
      language,
      languages,
      setLanguageAction,
    }),
    [language, languages, setLanguageAction]
  );

  return (
    <LanguageContext.Provider value={memorizedProviderLanguage}>
      {children}
    </LanguageContext.Provider>
  );
}

export default memo(LanguageProvider);
