import { IInitialCatalogCsvUploadState } from 'interfaces/catalogCsvUpload.interface';
import * as CatalogCsvUploadType from './catalogCsvUpload.types';

export default function superAdminReducer(
  state: IInitialCatalogCsvUploadState,
  action: any
) {
  const { type, payload } = action;

  switch (type) {
    case CatalogCsvUploadType.SET_REPORTS_BY_UPLOAD:
      const setReportsByUpload: IInitialCatalogCsvUploadState = {
        ...state,
        reportsByUpload: payload,
      };
      return setReportsByUpload;

    case CatalogCsvUploadType.SET_IS_LOADING_BY_UPLOAD:
      const setIsLoadingByUpload: IInitialCatalogCsvUploadState = {
        ...state,
        isLoadingByUpload: payload,
      };
      return setIsLoadingByUpload;

    case CatalogCsvUploadType.SET_PUBLISH_UPLOAD:
      const setPublishUpload: IInitialCatalogCsvUploadState = {
        ...state,
        reportsByUpload: state.reportsByUpload.map((report) => {
          if (report.id === payload.uploadId) {
            return { ...report, published: payload.publish };
          }
          return report;
        }),
      };
      return setPublishUpload;

    case CatalogCsvUploadType.SET_IS_LOADING_PUBLISH_UPLOAD:
      const setIsLoadingPublishUpload: IInitialCatalogCsvUploadState = {
        ...state,
        isLoadingPublishUpload: payload,
      };
      return setIsLoadingPublishUpload;

    case CatalogCsvUploadType.SET_REJECT_UPLOAD:
      const setRejectUpload: IInitialCatalogCsvUploadState = {
        ...state,
        reportsByUpload: state.reportsByUpload.filter((report) => report.id !== payload),
      };
      return setRejectUpload;

    case CatalogCsvUploadType.SET_IS_LOADING_REJECT_UPLOAD:
      const setIsLoadingRejectUpload: IInitialCatalogCsvUploadState = {
        ...state,
        isLoadingRejectUpload: {
          isLoading: payload.isLoading,
          uploadId: payload.uploadId,
        },
      };
      return setIsLoadingRejectUpload;

    case CatalogCsvUploadType.SET_REPORTS_BY_SEASON:
      const setReportsBySeason: IInitialCatalogCsvUploadState = {
        ...state,
        reportsBySeason: payload,
      };
      return setReportsBySeason;

    case CatalogCsvUploadType.SET_IS_LOADING_BY_SEASON:
      const setIsLoadingBySeason: IInitialCatalogCsvUploadState = {
        ...state,
        isLoadingBySeason: payload,
      };
      return setIsLoadingBySeason;

    case CatalogCsvUploadType.SET_START_SEASON_DATE:
      const setStartSeasonDate: IInitialCatalogCsvUploadState = {
        ...state,
        reportsBySeason: state.reportsBySeason.map((report) => {
          if (report.id === payload.id) {
            return { ...report, start: payload.date };
          }
          return report;
        }),
      };
      return setStartSeasonDate;

    case CatalogCsvUploadType.SET_END_SEASON_DATE:
      const setEndSeasonDate: IInitialCatalogCsvUploadState = {
        ...state,
        reportsBySeason: state.reportsBySeason.map((report) => {
          if (report.id === payload.id) {
            return { ...report, end: payload.date };
          }
          return report;
        }),
      };
      return setEndSeasonDate;

    case CatalogCsvUploadType.SET_PUBLISH_SEASON:
      const setPublishSeason: IInitialCatalogCsvUploadState = {
        ...state,
        reportsBySeason: state.reportsBySeason.map((report) => {
          if (report.id === payload.id) {
            return { ...report, enabled: payload.enabled };
          }
          return report;
        }),
      };
      return setPublishSeason;

    case CatalogCsvUploadType.SET_IS_LOADING_ENABLE_SEASON:
      const setIsLoadingEnableSeason: IInitialCatalogCsvUploadState = {
        ...state,
        isLoadingEnableSeason: {
          isLoading: payload.isLoading,
          seasonId: payload.seasonId,
        },
      };
      return setIsLoadingEnableSeason;

    case CatalogCsvUploadType.SET_CSV_SELECTED:
      const setCSVSelected: IInitialCatalogCsvUploadState = {
        ...state,
        csvSelected: {
          papa: payload.papa,
          file: payload.file,
        },
      };
      return setCSVSelected;

    case CatalogCsvUploadType.SET_IS_LOADING_CSV_SELECTED:
      const setIsLoadingCSVSelected: IInitialCatalogCsvUploadState = {
        ...state,
        isLoadingCSVSelected: payload,
      };
      return setIsLoadingCSVSelected;

    case CatalogCsvUploadType.SET_CSV_MAPPINGS:
      const setCSVMappings: IInitialCatalogCsvUploadState = {
        ...state,
        csvMappings: payload,
      };
      return setCSVMappings;

    case CatalogCsvUploadType.SET_CSV_MAPPING_KEYS:
      const setCSVMappingKeys: IInitialCatalogCsvUploadState = {
        ...state,
        csvMappingKeys: payload,
      };
      return setCSVMappingKeys;

    case CatalogCsvUploadType.SET_IS_LOADING_CSV_MAPPINGS:
      const setIsLoadingCSVMappings: IInitialCatalogCsvUploadState = {
        ...state,
        isLoadingCSVMappings: payload,
      };
      return setIsLoadingCSVMappings;

    case CatalogCsvUploadType.DELETE_MAPPING:
      const deleteMapping: IInitialCatalogCsvUploadState = {
        ...state,
        csvMappings: state.csvMappings.filter((mapping) => mapping.id !== payload),
      };
      return deleteMapping;

    case CatalogCsvUploadType.CREATE_MAPPING:
      const createMapping: IInitialCatalogCsvUploadState = {
        ...state,
        csvMappings: [...state.csvMappings, payload],
      };
      return createMapping;

    case CatalogCsvUploadType.SET_IS_LOADING_SAVE_CSV:
      const setIsLoadingSaveCSV: IInitialCatalogCsvUploadState = {
        ...state,
        isLoadingSaveCatalogCSVFile: payload,
      };
      return setIsLoadingSaveCSV;

    default:
      return state;
  }
}
