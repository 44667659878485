import { getCatalog, getCatalogApi } from 'api/catalog.api';
import * as CommercialProfileTypes from './commercialProfile.types';
import { showMoreProducts } from 'utils/constants';
import { catalogError, commercialProfileError } from 'utils/errorCodes';
import { notificationErr } from 'views/components/UI/notification';
import { messageError, messageSuccess } from 'views/components/UI/message';
import {
  IBodyProductSearch,
  ICatalogItem,
  TQuerySearchArray,
  TSortBy,
} from 'interfaces/catalog.interface';
import {
  IBodyMultimediaFetch,
  MultimediaItemState,
} from 'interfaces/multimedia.interface';
import { addMediaToProfileAPI, addProductsToProfileAPI } from 'api/commercialProfile.api';
import { findMultimediaAPI } from 'api/multimedia.api';
import {
  bannerType,
  videoType,
} from 'views/pages/main/multimediaPage/components/constants';
import { TinitialCommercialProfileState } from 'interfaces/commercialProfile.interface';
import { getCompanyInfoAPI, updateTenantDataAPI } from 'api/company.api';

export async function getComercialProfileDataAction(
  dispatch: any,
  brandId: string,
  lanuageId: string,
  translate: any,
  token: string
) {
  let multimediaBody: IBodyMultimediaFetch = {
    options: {
      profile: true,
      brand_id: [brandId],
      multimedia_type: [],
      search: [],
      limit: 100,
      index: 0,
    },
    filter: {
      tag: [],
      product: [],
      division: [],
      gender: [],
      country: [],
      season: [],
    },
  };

  // Get banners
  try {
    multimediaBody.options.multimedia_type = [bannerType];
    const getBannersFetch = await findMultimediaAPI(multimediaBody, undefined, token);
    if (getBannersFetch.response.status === 200) {
      dispatch({
        type: CommercialProfileTypes.SET_BANNER,
        payload: getBannersFetch.data[0],
      });
    }
  } catch (error) {
    console.log(error);
  }

  // Get videos
  try {
    multimediaBody.options.multimedia_type = [videoType];
    const getVideosFetch = await findMultimediaAPI(multimediaBody, undefined, token);
    if (getVideosFetch.response.status === 200) {
      dispatch({
        type: CommercialProfileTypes.SET_VIDEOS,
        payload: getVideosFetch.data,
      });
    }
  } catch (error) {
    console.log(error);
  }

  const body: IBodyProductSearch = {
    options: {
      brand_id: [brandId],
      favorite: '',
      group: [],
      with_images: null,
      profile: true,
      search: [],
      language_id: lanuageId,
      order: [],
      limit: 20,
      index: 0,
    },
    filter: {
      reference: [],
      color: [],
      season: [],
      segmentation: [],
      division: [],
      family: [],
      gender: [],
      tags: [],
      tiers: [],
      pvi: [],
      pvpr: [],
    },
  };

  // Get products
  try {
    const getProductsFetch = await getCatalogApi(body, token);
    if (getProductsFetch.response.status === 200) {
      dispatch({
        type: CommercialProfileTypes.SET_PRODUCTS,
        payload: getProductsFetch.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export async function setBannerAction(
  dispatch: any,
  banner: MultimediaItemState,
  state: TinitialCommercialProfileState,
  translate: any,
  token: string
) {
  try {
    let multimediaBody = [{ id: banner.id, profile: true }];
    if (state.banner?.id) {
      multimediaBody.push({
        id: state.banner.id,
        profile: false,
      });
    }
    const addMediaToProfileFetch = await addMediaToProfileAPI(multimediaBody, token);
    if (addMediaToProfileFetch.response.status === 200) {
      dispatch({
        type: CommercialProfileTypes.SET_BANNER,
        payload: banner,
      });
      return messageSuccess(
        translate('commercial-proile_actions_set-banner-action-success')
      );
    } else {
      messageError(translate('commercial-proile_actions_set-banner-action-error'));
    }
  } catch (err) {
    console.log(err);
    messageError(translate('commercial-proile_actions_set-banner-action-server-error'));
  }
}

export async function initBioAction(
  dispatch: any,
  tenantId: string,
  countryId: string,
  translate: any,
  token: string
) {
  try {
    const companyFetch = await getCompanyInfoAPI({ tenantId, countryId, token });
    if (companyFetch.response.status === 200) {
      return dispatch({
        type: CommercialProfileTypes.SET_BIO,
        payload: companyFetch.data?.bio ?? '',
      });
    }
    if (companyFetch.response.status === 404) {
      return dispatch({
        type: CommercialProfileTypes.SET_BIO,
        payload: '',
      });
    }
    messageError(translate('commercial-profile_actions_init-bio_error'));
    return notificationErr(commercialProfileError.init_bio_002, translate);
  } catch (err) {
    messageError(translate('commercial-profile_actions_init-bio_error'));
    return notificationErr(commercialProfileError.init_bio_001, translate);
  }
}

export async function updateBioAction(
  dispatch: any,
  bio: string,
  tenantId: string,
  translate: any,
  token: string
) {
  try {
    const body = { filter: { id: tenantId }, update: { bio: bio } };
    const companyFetch = await updateTenantDataAPI(body, token);
    if (companyFetch.response.status === 200) {
      messageSuccess(translate('commercial-profile_actions_update-bio_success'));
      return dispatch({
        type: CommercialProfileTypes.SET_BIO,
        payload: bio,
      });
    } else {
      messageError(translate('commercial-profile_actions_update-bio_error'));
      return notificationErr(commercialProfileError.update_bio_002, translate);
    }
  } catch (err) {
    messageError(translate('commercial-profile_actions_update-bio_error'));
    return notificationErr(commercialProfileError.update_bio_001, translate);
  }
}

export async function setProductsAction(
  dispatch: any,
  products: ICatalogItem[],
  state: TinitialCommercialProfileState,
  translate: any,
  token: string
) {
  let productsBody = products.map((product) => ({ id: product.id, profile: true }));
  let productsBodyIds = products.map((product) => product.id);
  for (const stateProduct of state.products) {
    if (!productsBodyIds.includes(stateProduct.id)) {
      productsBody.push({ id: stateProduct.id, profile: false });
    }
  }
  try {
    const addProductsToProfileFetch = await addProductsToProfileAPI(productsBody, token);
    if (addProductsToProfileFetch.response.status === 200) {
      dispatch({
        type: CommercialProfileTypes.SET_PRODUCTS,
        payload: products,
      });
      return messageSuccess(
        translate('commercial-proile_actions_set-products-action-success')
      );
    } else {
      messageError(translate('commercial-proile_actions_set-products-action-error'));
    }
  } catch (err) {
    console.log(err);
    messageError(translate('commercial-proile_actions_set-products-action-server-error'));
  }
}

export async function removeProductAction(
  dispatch: any,
  product: ICatalogItem,
  translate: any,
  token: string
) {
  try {
    const removeProductFromProfileFetch = await addProductsToProfileAPI(
      [{ id: product.id, profile: false }],
      token
    );
    if (removeProductFromProfileFetch.response.status === 200) {
      return dispatch({
        type: CommercialProfileTypes.REMOVE_PRODUCT,
        payload: product,
      });
    } else {
      messageError(translate('commercial-proile_actions_remove-product-action-error'));
    }
  } catch (err) {
    console.log(err);
    messageError(
      translate('commercial-proile_actions_remove-product-action-server-error')
    );
  }
}

export async function setVideosAction(
  dispatch: any,
  videos: MultimediaItemState[],
  state: TinitialCommercialProfileState,
  translate: any,
  token: string
) {
  let multimediaBody = videos.map((video) => ({ id: video.id, profile: true }));
  let multimediaBodyIds = videos.map((video) => video.id);
  for (const stateVideo of state.videos) {
    if (!multimediaBodyIds.includes(stateVideo.id)) {
      multimediaBody.push({ id: stateVideo.id, profile: false });
    }
  }
  try {
    const addMediaToProfileFetch = await addMediaToProfileAPI(multimediaBody, token);
    if (addMediaToProfileFetch.response.status === 200) {
      dispatch({
        type: CommercialProfileTypes.SET_VIDEOS,
        payload: videos,
      });
      return messageSuccess(
        translate('commercial-proile_actions_set-videos-action-success')
      );
    } else {
      messageError(translate('commercial-proile_actions_set-videos-action-error'));
    }
  } catch (err) {
    console.log(err);
    messageError(translate('commercial-proile_actions_set-videos-action-server-error'));
  }
}

export async function removeVideoAction(
  dispatch: any,
  video: MultimediaItemState,
  translate: any,
  token: string
) {
  try {
    const removeMediaToProfileFetch = await addMediaToProfileAPI(
      [{ id: video.id, profile: false }],
      token
    );
    if (removeMediaToProfileFetch.response.status === 200) {
      return dispatch({
        type: CommercialProfileTypes.REMOVE_VIDEO,
        payload: video,
      });
    } else {
      messageError(translate('commercial-proile_actions_remove-video-action-error'));
    }
  } catch (err) {
    messageError(translate('commercial-proile_actions_remove-video-action-server-error'));
    console.log(err);
  }
}

// Catalog
export async function getCatalogAction(
  dispatch: any,
  bodyProductSearch: IBodyProductSearch,
  translate: any,
  signal: AbortSignal | undefined,
  token: string
) {
  dispatch({ type: CommercialProfileTypes.SET_IS_LOADING, payload: true });
  try {
    bodyProductSearch.options.index = 0;
    dispatch({
      type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH,
      payload: bodyProductSearch,
    });
    dispatch({
      type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH_TEMP,
      payload: bodyProductSearch,
    });
    const products = await getAndOrderImagesCatalog(
      dispatch,
      bodyProductSearch,
      signal,
      token
    );
    if (products) {
      dispatch({
        type: CommercialProfileTypes.INCREMENT_PAGINATION_INDEX,
        payload: bodyProductSearch.options.index + showMoreProducts,
      });
      return dispatch({
        type: CommercialProfileTypes.GET_CATALOG,
        payload: products,
      });
    }
  } catch (err) {
    console.log(err);
    // return notificationErr(catalogError.get_catalog_001, translate)
  } finally {
    dispatch({ type: CommercialProfileTypes.SET_IS_LOADING, payload: false });
  }
}

export async function addMoreProductsToCatalogAction(
  dispatch: any,
  bodyProductSearch: any,
  translate: any,
  token: string
) {
  if (bodyProductSearch.options.index === 0) {
    bodyProductSearch.options.index = 10;
  }
  dispatch({ type: CommercialProfileTypes.SET_IS_LOADING_MORE_PRODUCTS, payload: true });
  dispatch({
    type: CommercialProfileTypes.INCREMENT_PAGINATION_INDEX,
    payload: bodyProductSearch.options.index + showMoreProducts,
  });
  try {
    const products = await getAndOrderImagesCatalog(
      dispatch,
      bodyProductSearch,
      undefined,
      token
    );
    if (products) {
      return dispatch({
        type: CommercialProfileTypes.ADD_MORE_TO_CATALOG,
        payload: products,
      });
    }
  } catch (err) {
    // return notificationErr(catalogError.add_more_products_to_catalog_001, translate)
  } finally {
    dispatch({
      type: CommercialProfileTypes.SET_IS_LOADING_MORE_PRODUCTS,
      payload: false,
    });
  }
}

// Filters
export async function filterAction(
  dispatch: any,
  setOpenDrawer: any,
  commercialProfileState: any,
  translate: any,
  signal: AbortSignal | undefined,
  token: string
) {
  setOpenDrawer(false);
  dispatch({ type: CommercialProfileTypes.SET_IS_LOADING, payload: true });
  dispatch({ type: CommercialProfileTypes.INCREMENT_PAGINATION_INDEX, payload: 0 });
  commercialProfileState.bodyProductSearch.options.index = 0;
  commercialProfileState.bodyProductSearch = commercialProfileState.bodyProductSearchTemp;
  dispatch({
    type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH,
    payload: commercialProfileState.bodyProductSearchTemp,
  });
  dispatch({
    type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH_TEMP,
    payload: commercialProfileState.bodyProductSearch,
  });
  try {
    const products = await getAndOrderImagesCatalog(
      dispatch,
      commercialProfileState.bodyProductSearch,
      signal,
      token
    );
    if (products) {
      return dispatch({
        type: CommercialProfileTypes.GET_CATALOG,
        payload: products,
      });
    } else {
      return dispatch({
        type: CommercialProfileTypes.GET_CATALOG,
        payload: [],
      });
    }
  } catch (err) {
    return notificationErr(catalogError.filter_catalog_001, translate);
  } finally {
    dispatch({ type: CommercialProfileTypes.SET_IS_LOADING, payload: false });
  }
}

export async function addBodyFiltersAction(dispatch: any, field: string, value: string) {
  return dispatch({
    type: CommercialProfileTypes.ADD_BODY_FILTERS,
    payload: {
      field,
      value,
    },
  });
}

export async function removeBodyFiltersAction(
  dispatch: any,
  field: string,
  value: string
) {
  return dispatch({
    type: CommercialProfileTypes.REMOVE_BODY_FILTERS,
    payload: {
      field,
      value,
    },
  });
}

export async function resetBodyFiltersAction(
  dispatch: any,
  bodyProductSearch: IBodyProductSearch,
  setOpenDrawer: any,
  translate: any,
  signal: AbortSignal | undefined,
  token: string
) {
  setOpenDrawer && setOpenDrawer(false);
  bodyProductSearch.filter.color = [];
  bodyProductSearch.filter.season = [];
  bodyProductSearch.filter.segmentation = [];
  bodyProductSearch.filter.division = [];
  bodyProductSearch.filter.family = [];
  bodyProductSearch.filter.gender = [];
  bodyProductSearch.filter.pvi = [];
  bodyProductSearch.filter.pvpr = [];
  bodyProductSearch.options.favorite = '';
  dispatch({
    type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH,
    payload: bodyProductSearch,
  });
  dispatch({
    type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH_TEMP,
    payload: bodyProductSearch,
  });
  getCatalogAction(dispatch, bodyProductSearch, translate, signal, token);
  dispatch({ type: CommercialProfileTypes.SET_THERE_ARE_MORE_PRODUCTS, payload: true });
  dispatch({ type: CommercialProfileTypes.SET_IS_EMPTY_PRODUCTS, payload: false });
  return dispatch({ type: CommercialProfileTypes.RESET_BODY_FILTERS });
}

export async function setPriceSlidersAction(dispatch: any, field: string, value: string) {
  return dispatch({
    type: CommercialProfileTypes.SET_PRICE_SLIDERS,
    payload: {
      field,
      value,
    },
  });
}

export async function setFavoritesAction(dispatch: any, userId: string) {
  dispatch({ type: CommercialProfileTypes.INCREMENT_PAGINATION_INDEX, payload: 0 });
  return dispatch({ type: CommercialProfileTypes.SET_FAVORITES, payload: userId });
}

export async function resetPriceSliderAction(
  dispatch: any,
  type: string,
  bodyProductSearch: any,
  translate: any,
  signal: AbortSignal | undefined,
  token: string
) {
  dispatch({ type: CommercialProfileTypes.RESET_PRICE_SLIDER, payload: { type: type } });
  bodyProductSearch.filter[type] = [];
  getCatalogAction(dispatch, bodyProductSearch, translate, signal, token);
}

// Search Engine //////////////////////////////////////////////////////
export async function setQuerySearchAction(dispatch: any, query: string) {
  return dispatch({ type: CommercialProfileTypes.SET_QUERY_SEARCH, payload: query });
}

export async function addToBodySearchAction(
  dispatch: any,
  query: string,
  commercialProfileState: TinitialCommercialProfileState,
  translate: any,
  signal: AbortSignal | undefined,
  token: string
) {
  try {
    commercialProfileState.bodyProductSearch.options.index = 0;
    commercialProfileState.bodyProductSearch.options.search.push(`%${query}%`);
    dispatch({
      type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH,
      payload: commercialProfileState.bodyProductSearch,
    });
    dispatch({
      type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH_TEMP,
      payload: commercialProfileState.bodyProductSearch,
    });
    getCatalogAction(
      dispatch,
      commercialProfileState.bodyProductSearch,
      translate,
      signal,
      token
    );
  } catch (err) {
    console.log(err);
  }
}

export async function removeFromBodySearchAction(
  dispatch: any,
  query: string,
  commercialProfileState: TinitialCommercialProfileState,
  translate: any,
  signal: AbortSignal | undefined,
  token: string
) {
  try {
    commercialProfileState.bodyProductSearch.options.index = 0;
    const filteredSearch = commercialProfileState.bodyProductSearch.options.search.filter(
      (item: any) => item !== query
    );
    commercialProfileState.bodyProductSearch.options.search = filteredSearch;
    commercialProfileState.bodyProductSearchTemp =
      commercialProfileState.bodyProductSearch;
    dispatch({
      type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH,
      payload: commercialProfileState.bodyProductSearch,
    });
    dispatch({
      type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH_TEMP,
      payload: commercialProfileState.bodyProductSearch,
    });
    getCatalogAction(
      dispatch,
      commercialProfileState.bodyProductSearch,
      translate,
      signal,
      token
    );
  } catch (err) {
    console.log(err);
  }
}

export async function removeAllFromBodySearchAction(
  dispatch: any,
  commercialProfileState: TinitialCommercialProfileState,
  translate: any,
  signal: AbortSignal | undefined,
  token: string
) {
  try {
    commercialProfileState.bodyProductSearch.options.index = 0;
    commercialProfileState.bodyProductSearch.options.search = [];
    commercialProfileState.bodyProductSearch.options.favorite = '';
    commercialProfileState.bodyProductSearch.filter = {
      reference: [],
      color: [],
      season: [],
      segmentation: [],
      division: [],
      family: [],
      gender: [],
      pvi: [],
      pvpr: [],
      tags: [],
      tiers: [],
    };
    commercialProfileState.bodyProductSearchTemp =
      commercialProfileState.bodyProductSearch;
    commercialProfileState.querysearchArray = [];
    dispatch({
      type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH,
      payload: commercialProfileState.bodyProductSearch,
    });
    dispatch({
      type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH_TEMP,
      payload: commercialProfileState.bodyProductSearch,
    });
    getCatalogAction(
      dispatch,
      commercialProfileState.bodyProductSearch,
      translate,
      signal,
      token
    );
  } catch (err) {
    console.log(err);
  }
}

export async function removeFromBodySearchFilterAction(
  dispatch: any,
  value: any,
  commercialProfileState: any,
  translate: any,
  signal: AbortSignal | undefined,
  token: string
) {
  const { tag, field } = value;
  try {
    commercialProfileState.bodyProductSearch.options.index = 0;
    value.value.forEach((id: string) => {
      commercialProfileState.bodyProductSearch.filter[field] =
        commercialProfileState.bodyProductSearch.filter[field].filter(
          (item: any) => item !== id
        );
    });
    const filteredQuerySearchArray = commercialProfileState.querysearchArray.filter(
      (item: any) => item.tag !== tag
    );
    dispatch({
      type: CommercialProfileTypes.SET_BODY_SEARCH_FILTER,
      payload: {
        querySearchArray: filteredQuerySearchArray,
      },
    });
    dispatch({
      type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH,
      payload: commercialProfileState.bodyProductSearch,
    });
    dispatch({
      type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH_TEMP,
      payload: commercialProfileState.bodyProductSearch,
    });
    getCatalogAction(
      dispatch,
      commercialProfileState.bodyProductSearch,
      translate,
      signal,
      token
    );
  } catch (err) {
    console.log(err);
  }
}

export async function addToQuerySearchArrayAction(
  dispatch: any,
  value: TQuerySearchArray
) {
  return dispatch({
    type: CommercialProfileTypes.ADD_TO_QUERY_SEARCH_ARRAY,
    payload: value,
  });
}

export async function removeFromQuerySearchArrayAction(
  dispatch: any,
  value: TQuerySearchArray
) {
  return dispatch({
    type: CommercialProfileTypes.REMOVE_FROM_QUERY_SEARCH_ARRAY,
    payload: value,
  });
}

// Sort By //////////////////////////////////////////////////////
export async function setSortByAction(
  dispatch: any,
  sortBy: string,
  bodyProductSearch: IBodyProductSearch,
  translate: any,
  signal: AbortSignal | undefined,
  token: string
) {
  bodyProductSearch.options.order = [sortBy as TSortBy];
  dispatch({ type: CommercialProfileTypes.SET_IS_LOADING, payload: true });
  dispatch({ type: CommercialProfileTypes.SET_SORT_BY, payload: sortBy });
  dispatch({
    type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH,
    payload: bodyProductSearch,
  });
  dispatch({
    type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH_TEMP,
    payload: bodyProductSearch,
  });
  try {
    const products = await getAndOrderImagesCatalog(
      dispatch,
      bodyProductSearch,
      signal,
      token
    );
    if (products) {
      return dispatch({
        type: CommercialProfileTypes.GET_CATALOG,
        payload: products,
      });
    }
  } catch (error) {
    console.log('error');
  } finally {
    return dispatch({ type: CommercialProfileTypes.SET_IS_LOADING, payload: false });
  }
}

export async function setSortByHaveImagesAction(
  dispatch: any,
  haveImages: boolean | null,
  bodyProductSearch: IBodyProductSearch,
  translate: any,
  signal: AbortSignal | undefined,
  token: string
) {
  dispatch({ type: CommercialProfileTypes.SET_IS_LOADING, payload: true });
  try {
    bodyProductSearch.options.with_images = haveImages;
    dispatch({
      type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH,
      payload: bodyProductSearch,
    });
    dispatch({
      type: CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH_TEMP,
      payload: bodyProductSearch,
    });
    await getCatalogAction(dispatch, bodyProductSearch, translate, signal, token);
  } catch (error) {
    console.log(error);
  } finally {
    return dispatch({ type: CommercialProfileTypes.SET_IS_LOADING, payload: false });
  }
}

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

async function getAndOrderImagesCatalog(
  dispatch: any,
  body: any,
  signal: AbortSignal | undefined,
  token: string
) {
  const catalogFetch = await getCatalog(body, token, signal);
  dispatch({ type: CommercialProfileTypes.SET_IS_EMPTY_PRODUCTS, payload: false });
  if (catalogFetch.response.status === 404 || catalogFetch.data.data.length === 0) {
    dispatch({ type: CommercialProfileTypes.SET_IS_EMPTY_PRODUCTS, payload: true });
    return {
      count: 0,
      data: [],
    };
    // return dispatch({ type: CommercialProfileTypes.SET_THERE_ARE_MORE_PRODUCTS, payload: false })
  }
  if (catalogFetch.data.data.length < showMoreProducts) {
    dispatch({
      type: CommercialProfileTypes.SET_THERE_ARE_MORE_PRODUCTS,
      payload: false,
    });
  } else {
    dispatch({ type: CommercialProfileTypes.SET_THERE_ARE_MORE_PRODUCTS, payload: true });
  }
  const products = catalogFetch.data.data;
  products.forEach(async (item: any) => {
    if (item?.images && item?.images?.length > 0) {
      item.images.reverse();
      item.images.forEach((element: any, index: number) => {
        const ext = getExtension(element);
        const getImageNumber = ext.split('.')[0];
        // eslint-disable-next-line eqeqeq
        if (getImageNumber[1] == 1 && Number(getImageNumber[0]) !== 1) {
          item.images.slice(index, 1);
          item.images.unshift(element);
        }
        const result = item.images.reduce((acc: any, item: any) => {
          if (!acc.includes(item)) {
            acc.push(item);
          }
          return acc;
        }, []);
        item.images = result;
      });
    }
  });
  return {
    count: catalogFetch.data.count,
    data: products,
  };
}

function getExtension(url: any) {
  return (url = url.substr(1 + url.lastIndexOf('/')).split('?')[0])
    .split('#')[0]
    .substr(url.lastIndexOf('.') - 2);
}
