import { message } from 'antd';

const makeConfig = (duration: number) => {
  return message.config({
    top: 35,
    duration: duration,
    maxCount: 3,
  });
};

export const messageSuccess = (msg: string, duration: number = 4) => {
  makeConfig(duration);
  return message.success(msg, duration);
};

export const messageInfo = (msg: string, duration: number = 4) => {
  makeConfig(duration);
  return message.info(msg, duration);
};

export const messageWarning = (msg: string, duration: number = 4) => {
  makeConfig(duration);
  return message.warning(msg, duration);
};

export const messageError = (msg: string, duration: number = 5) => {
  makeConfig(duration);
  return message.error(msg, duration);
};

export const messageLoading = (msg: string, duration: number = 1) => {
  makeConfig(duration);
  return message.loading(msg, duration);
};
