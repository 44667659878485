import {
  TcreateMapBodyProps,
  TcreateSftpUserAPIBodyProps,
  TcreateShopifyConnectionAPIBodyProps,
  TupdateMapBodyProps,
} from 'api/responseInterfaces/meta.response.interface';
import { IMapField } from './superAdmin.interface';
import { TupdateShopifyConnectionAPIBodyUpdateProps } from 'api/responseInterfaces/superAdmin.response.interface';

export interface IMappingPointOfSale {
  name: string;
  id: string;
}

export type TConnectorTypes = 'sftp' | 'shopify' | 'prestashop' | 'odoo';
export const connectorTypes = {
  sftp: 'sftp',
  shopify: 'shopify',
  prestashop: 'prestashop',
  odoo: 'odoo',
};

export interface IInitialMetaState {
  getConnections: () => Promise<boolean | null>;
  isLoadingConnections: boolean;
  // Maps
  getMaps: ({ connectorType, clientId }: IgetMapsProps) => Promise<boolean>;
  isLoadingMaps: boolean;
  createMap: ({ connectorType, body, connection }: ICreateMapProps) => Promise<boolean>;
  isLoadingCreateMap: boolean;
  updateMap: ({ connectorType, body }: IUpdateMapProps) => Promise<boolean>;
  isLoadingUpdateMap: boolean;
  addMapToConnection: ({
    connectorType,
    mapIds,
    connectionId,
  }: IaddMapToConnectionProps) => Promise<boolean>;
  isLoadingAddMapToConnection: boolean;
  deleteMapFromConnection: ({
    connectorType,
    connectionId,
    mapIds,
  }: IdeleteMapFromConnectionProps) => Promise<boolean>;
  isLoadingDeleteMapFromConnection: boolean;
  sftp: {
    connections: TSftpUserItem[];
    createSftpConnection: (body: TcreateSftpUserAPIBodyProps) => Promise<boolean>;
    isLoadingCreateSftpConnection: boolean;
    removeSftpConnection: (user_name: string) => void;
    isLoadingRemoveSftpConnection: {
      loading: boolean;
      user_name: string;
    };
  };
  shopify: {
    // Connections
    connections: Array<TShopifyConnectionItem>;
    connectionSelected: TShopifyConnectionItem;
    createShopifyConnection: ({
      body,
    }: {
      body: TcreateShopifyConnectionAPIBodyProps;
    }) => Promise<boolean>;
    isLoadingCreateShopifyConnection: boolean;
    updateShopifyConnection: ({
      body,
      connectionId,
    }: {
      body: TupdateShopifyConnectionAPIBodyUpdateProps;
      connectionId: string;
    }) => Promise<boolean>;
    isLoadingUpdateShopifyConnection: boolean;
    removeShopifyConnection: ({
      connectionId,
    }: {
      connectionId: string;
    }) => Promise<boolean>;
    isLoadingRemoveShopifyConnection: {
      loading: boolean;
      connection_id: string;
    };
    // Mappings
    mappings: Array<IMap>;
    // Configuration
    configuration: IShopyfyConfigurationItem;
    getShopifyConfiguration: ({
      connectorId,
    }: IgetConfigurationProps) => Promise<boolean>;
    isLoadingConfiguration: boolean;
    createUpdateShopifyConfiguration: ({
      connector_id,
      queries,
    }: IcreateUpdateConfigurationProps) => Promise<boolean>;
    isLoadingCreateUpdateConfiguration: boolean;
  };
}

// Props ///////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
export interface IgetMapsProps {
  connectorType: TConnectorTypes;
  clientId: string;
}
export interface ICreateMapProps {
  connectorType: TConnectorTypes;
  body: TcreateMapBodyProps;
  connection: TSftpUserItem | TShopifyConnectionItem;
}
export interface IUpdateMapProps {
  connectorType: TConnectorTypes;
  body: TupdateMapBodyProps;
}
export interface IaddMapToConnectionProps {
  connectorType: TConnectorTypes;
  mapIds: string[];
  connectionId: string;
}
export interface IdeleteMapFromConnectionProps {
  connectorType: TConnectorTypes;
  connectionId: string;
  mapIds: string[];
}
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

// SFTP ////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
export type TSftpUserItem = {
  client_id: string;
  brand_id: string | null;
  id: string;
  type?: TConnectorTypes;
  name: string;
  period: number;
  password: string;
  updatedAt: string;
  createdAt: string;
};

// SHOPIFY /////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
export type TShopifyConnectionItem = {
  id: string;
  client_id: string;
  brand_id: string | null;
  type: TConnectorTypes;
  tag: string;
  period: number;
  base_url: string;
  from_year: number;
  createdAt: string;
  updatedAt: string;
  api_key: string;
  api_secret_key: string;
  access_token: string;
  map_id: string | null;
  last_upload: string | null;
  last_connection: string | null;
  error: boolean;
  shopify_connector_maps: string[];
};

// Shopify configuration ///////////////////////
export interface IShopyfyConfigurationItem {
  id: string;
  connector_id: string;
  queries: Array<string>;
  createdAt: string;
  updatedAt: string;
}

export interface IgetConfigurationProps {
  connectorId: string;
}

export interface IcreateUpdateConfigurationProps {
  connector_id: string;
  queries: Array<string>;
}
////////////////////////////////////////////////

// Map //////////////////////////////////////////

export type TMappingTypes = 'catalog' | 'sale' | 'image' | 'orders' | 'budget';
export const mappingTypes = {
  catalog: 'catalog',
  sale: 'sale',
  image: 'image',
  orders: 'orders',
  budget: 'budget',
};

export type IMapFieldsTypes =
  | 'replace_key'
  | 'replace_value'
  | 'search'
  | 'substring'
  | 'pattern'
  | 'date'
  | 'loop'
  | 'delete'
  | 'clean';
export const mapFieldsTypes = {
  replace_key: 'replace_key',
  replace_value: 'replace_value',
  search: 'search',
  substring: 'substring',
  pattern: 'pattern',
  date: 'date',
  loop: 'loop',
  delete: 'delete',
  clean: 'clean',
};

export interface IMapFieldMap {
  id: string;
  map_id: string;
  map_field_id: string;
  createdAt: string;
  updatedAt: string;
  map_field: IMapField;
}

export interface IMap {
  id: string;
  tag: string;
  description: string | null;
  brand_id: string;
  client_id: string | null;
  user_id: string | null;
  period: number;
  origin: string;
  audience: string;
  signature: string | null;
  separator: string | null;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
  map_field_maps: IMapFieldMap[];
}
