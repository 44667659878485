import { useContext } from 'react';
import CatalogContext from 'contexts/catalog/CatalogContext';

export default function useContextCatalog() {
  const context = useContext(CatalogContext);
  if (context === undefined) {
    throw new Error('useContextCatalog must be used within a CatalogProvider');
  }
  return context;
}
