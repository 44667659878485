import { RouterProvider } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import router from './router/router';
import MenuMobileProvider from 'contexts/UI/menuMobile/MenuMobileProvider';
import UserProvider from 'contexts/user/UserProvider';
import LanguageProvider from 'contexts/UI/language/LanguageProvider';
import CatalogProvider from 'contexts/catalog/CatalogProvider';
import MainContactsProvider from 'contexts/mainContacts/MainContactsProvider';
import ComercialInfoProvider from 'contexts/comercialInfo/ComercialInfoProvider';
import MultimediaProvider from 'contexts/multimedia/MultimediaProvider';
import DrawersMultimediaProvider from 'contexts/UI/drawersMultimedia/DrawersMultimediaProvider';
import ClientsProvider from 'contexts/clients/ClientsProvider';
// import ClientContactsProvider from 'contexts/clientsContacts/ClientContactsProvider'
import BrandUsersProvider from 'contexts/brandUsers/BrandUsersProvider';
import GoogleAddressProvider from 'contexts/UI/googleAddress/GoogleAddressProvider';
import { memo, useCallback } from 'react';
import NotificationsProvider from 'contexts/notifications/NotificationsProvider';
import CommercialProfileProvider from 'contexts/commercialProfile/CommercialProfileProvider';
import ModalsProvider from 'contexts/UI/modals/ModalsProvider';
import { dashboardSale } from 'router/paths';
import SuperAdminProvider from 'contexts/superAdmin/SuperAdminProvider';
import CatalogCsvUploadProvider from 'contexts/catalogCsvUpload/CatalogCsvUploadProvider';
import CatalogCorrelationsProvider from 'contexts/catalogCorrelations/CatalogCorrelationsProvider';
import CatalogGenericProvider from 'contexts/catalogGenerics/CatalogGenericProvider';
import CountriesProvider from 'contexts/UI/countries/CountriesProvider';
import CatalogDownloadProvider from 'contexts/catalogDownload/CatalogDownloadProvider';
import MetaProvider from 'contexts/meta/MetaProvider';

function App() {
  const getClientId = useCallback(() => {
    if (process.env.REACT_APP_NODE_ENV === 'local' || !process.env.REACT_APP_NODE_ENV) {
      return process.env.REACT_APP_AUTH0_CLIENT_ID_LOCAL;
    } else if (process.env.REACT_APP_NODE_ENV === 'prod') {
      return process.env.REACT_APP_AUTH0_CLIENT_ID_PRODUCTION;
    } else if (process.env.REACT_APP_NODE_ENV === 'dev') {
      return process.env.REACT_APP_AUTH0_CLIENT_ID_DEV;
    } else if (process.env.REACT_APP_NODE_ENV === 'staging') {
      return process.env.REACT_APP_AUTH0_CLIENT_ID_STAGING;
    }
  }, []);
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={getClientId() as string}
      authorizationParams={{
        redirect_uri: window.location.origin + dashboardSale,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
      }}
    >
      <LanguageProvider>
        <UserProvider>
          <SuperAdminProvider>
            <CountriesProvider>
              <NotificationsProvider>
                <MenuMobileProvider>
                  <CatalogProvider>
                    <MultimediaProvider>
                      <ClientsProvider>
                        <MainContactsProvider>
                          <ComercialInfoProvider>
                            <CommercialProfileProvider>
                              <BrandUsersProvider>
                                <DrawersMultimediaProvider>
                                  <ModalsProvider>
                                    <GoogleAddressProvider>
                                      <CatalogCsvUploadProvider>
                                        <CatalogGenericProvider>
                                          <CatalogCorrelationsProvider>
                                            <CatalogDownloadProvider>
                                              <MetaProvider>
                                                <RouterProvider
                                                  router={router}
                                                  fallbackElement={<></>}
                                                />
                                              </MetaProvider>
                                            </CatalogDownloadProvider>
                                          </CatalogCorrelationsProvider>
                                        </CatalogGenericProvider>
                                      </CatalogCsvUploadProvider>
                                    </GoogleAddressProvider>
                                  </ModalsProvider>
                                </DrawersMultimediaProvider>
                              </BrandUsersProvider>
                            </CommercialProfileProvider>
                          </ComercialInfoProvider>
                        </MainContactsProvider>
                      </ClientsProvider>
                    </MultimediaProvider>
                  </CatalogProvider>
                </MenuMobileProvider>
              </NotificationsProvider>
            </CountriesProvider>
          </SuperAdminProvider>
        </UserProvider>
      </LanguageProvider>
    </Auth0Provider>
  );
}

export default memo(App);
