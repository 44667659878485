import { IBrandUser, IInitialBrandUsersState } from 'interfaces/brandUsers.interface';

const initialBrandUsersState: IInitialBrandUsersState = {
  brandUsers: [] as IBrandUser[],
  brandUsersSelected: {} as IBrandUser,
  isLoadingAction: false,
  getBrandUsers: () => {},
  selectBrandUser: (user: any) => {},
  createBrandUser: (newUser: any, setIsOpenModal: () => void) => {},
  updateBrandUser: (
    user: any,
    userId: string,
    setIsOpenModal: () => void,
    updateRole: boolean
  ) => {},
  deleteBrandUser: (userId: string) => {},
};

export default initialBrandUsersState;
