/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo, useEffect } from 'react';
import CommercialProfileContext from './CommercialProfileContext';
import initialCommercialProfileState from './initialCommercialProfileState';
import userReducer from 'contexts/commercialProfile/reducer/commercialProfile.reducer';
import * as action from 'contexts/commercialProfile/reducer/commercialProfile.actions';
import { useTranslation } from 'react-i18next';
import { abortCatalogController } from 'utils/abortController';
import { useAuth0 } from '@auth0/auth0-react';
import * as CommercialProfileTypes from 'contexts/commercialProfile/reducer/commercialProfile.types';
import { initialProductsQuantity } from 'utils/constants';
import useContextUser from 'hooks/contexts/useContextUser';
import { MultimediaItemState } from 'interfaces/multimedia.interface';
import { ICatalogItem, TQuerySearchArray } from 'interfaces/catalog.interface';
import { ChildrenProps } from 'types';

function CommercialProfileProvider(props: ChildrenProps) {
  const [state, dispatch] = useReducer(userReducer, initialCommercialProfileState);
  const { t: translate } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { dbUser, company } = useContextUser();

  useEffect(() => {
    if (!company.brand?.id) return;
    state.bodyProductSearch.options.brand_id[0] = company.brand?.id;
    state.bodyProductSearch.options.language_id = dbUser.language?.id;
    dispatch({
      type: CommercialProfileTypes.SET_BODY_PRODUCT,
      payload: {
        brandId: company.brand.id,
        limit: initialProductsQuantity,
        languageId: dbUser.language.id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.brand?.id, dbUser.language?.id]);

  const getComercialProfileData = useCallback(async () => {
    const token = await getAccessTokenSilently();
    action.getComercialProfileDataAction(
      dispatch,
      company.brand?.id,
      dbUser.language?.id,
      translate,
      token
    );
  }, [company.brand?.id, dbUser.language?.id, translate]);

  const setBanner = useCallback(
    async (banner: MultimediaItemState) => {
      const token = await getAccessTokenSilently();
      action.setBannerAction(dispatch, banner, state, translate, token);
    },
    [translate, state]
  );

  const initBio = useCallback(async () => {
    const token = await getAccessTokenSilently();
    action.initBioAction(dispatch, company.id, company.country.id, translate, token);
  }, [company.id, company.country?.id, translate]);

  const updateBio = useCallback(
    async (bio: string) => {
      const token = await getAccessTokenSilently();
      action.updateBioAction(dispatch, bio, company.id, translate, token);
    },
    [company.id, translate]
  );

  const setProducts = useCallback(
    async (products: ICatalogItem[]) => {
      const token = await getAccessTokenSilently();
      action.setProductsAction(dispatch, products, state, translate, token);
    },
    [translate, state, getAccessTokenSilently]
  );

  const removeProduct = useCallback(
    async (product: ICatalogItem) => {
      const token = await getAccessTokenSilently();
      action.removeProductAction(dispatch, product, translate, token);
    },
    [translate]
  );

  const setVideos = useCallback(
    async (videos: MultimediaItemState[]) => {
      const token = await getAccessTokenSilently();
      action.setVideosAction(dispatch, videos, state, translate, token);
    },
    [state, translate]
  );

  const removeVideo = useCallback(
    async (video: MultimediaItemState) => {
      const token = await getAccessTokenSilently();
      action.removeVideoAction(dispatch, video, translate, token);
    },
    [translate]
  );

  // Catalog
  const getCatalog = useCallback(async () => {
    const signal = abortCatalogController();
    const token = await getAccessTokenSilently();
    state.bodyProductSearch.options?.brand_id[0] &&
      action.getCatalogAction(
        dispatch,
        state.bodyProductSearch,
        translate,
        signal,
        token
      );
  }, [state.bodyProductSearch, translate]);

  const addMoreProductsToCatalog = useCallback(async () => {
    const token = await getAccessTokenSilently();
    state.bodyProductSearch &&
      action.addMoreProductsToCatalogAction(
        dispatch,
        state.bodyProductSearch,
        translate,
        token
      );
  }, [state.bodyProductSearch, translate]);

  // Filters
  const filter = useCallback(
    async (setOpenDrawer: any) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      action.filterAction(dispatch, setOpenDrawer, state, translate, signal, token);
    },
    [state, translate]
  );

  const addBodyFilters = useCallback(async (field: string, value: string) => {
    action.addBodyFiltersAction(dispatch, field, value);
  }, []);

  const removeBodyFilters = useCallback(async (field: string, value: string) => {
    action.removeBodyFiltersAction(dispatch, field, value);
  }, []);

  const resetBodyFilters = useCallback(
    async (setOpenDrawer: any) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      action.resetBodyFiltersAction(
        dispatch,
        state.bodyProductSearch,
        setOpenDrawer,
        translate,
        signal,
        token
      );
    },
    [state.bodyProductSearch, translate]
  );

  const setPriceSliders = useCallback(async (field: string, value: any) => {
    action.setPriceSlidersAction(dispatch, field, value);
  }, []);

  const setFavorites = useCallback(async (userId: string) => {
    action.setFavoritesAction(dispatch, userId);
  }, []);

  const resetPriceSlider = useCallback(
    async (type: string) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      action.resetPriceSliderAction(
        dispatch,
        type,
        state.bodyProductSearch,
        translate,
        signal,
        token
      );
    },
    [state.bodyProductSearch, translate]
  );

  // Search engine
  const setQuerySearch = useCallback(async (query: string) => {
    action.setQuerySearchAction(dispatch, query);
  }, []);

  const addToBodySearch = useCallback(
    async (query: string) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      action.addToBodySearchAction(dispatch, query, state, translate, signal, token);
    },
    [state, translate]
  );

  const removeFromBodySearch = useCallback(
    async (query: string) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      action.removeFromBodySearchAction(dispatch, query, state, translate, signal, token);
    },
    [state, translate]
  );

  const removeAllFromBodySearch = useCallback(async () => {
    const signal = abortCatalogController();
    const token = await getAccessTokenSilently();
    action.removeAllFromBodySearchAction(dispatch, state, translate, signal, token);
  }, [state, translate]);

  const removeFromBodySearchFilter = useCallback(
    async (value: any) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      action.removeFromBodySearchFilterAction(
        dispatch,
        value,
        state,
        translate,
        signal,
        token
      );
    },
    [state, translate]
  );

  const addToQuerySearchArray = useCallback(async (value: TQuerySearchArray) => {
    action.addToQuerySearchArrayAction(dispatch, value);
  }, []);

  const removeFromQuerySearchArray = useCallback(async (value: TQuerySearchArray) => {
    action.removeFromQuerySearchArrayAction(dispatch, value);
  }, []);

  // Sort By
  const setSortBy = useCallback(
    async (sortBy: string) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      action.setSortByAction(
        dispatch,
        sortBy,
        state.bodyProductSearch,
        translate,
        signal,
        token
      );
    },
    [state.bodyProductSearch, translate]
  );

  const setSortByHaveImages = useCallback(
    async (haveImages: boolean | null) => {
      const signal = abortCatalogController();
      const token = await getAccessTokenSilently();
      action.setSortByHaveImagesAction(
        dispatch,
        haveImages,
        state.bodyProductSearch,
        translate,
        signal,
        token
      );
    },
    [state.bodyProductSearch, translate]
  );

  const memoProvider = useMemo(
    () => ({
      ...state,
      getComercialProfileData,
      setBanner,
      initBio,
      // setBio,
      updateBio,
      setProducts,
      removeProduct,
      setVideos,
      removeVideo,
      // Catalog
      getCatalog,
      addMoreProductsToCatalog,
      // Filters
      filter,
      addBodyFilters,
      removeBodyFilters,
      resetBodyFilters,
      setPriceSliders,
      setFavorites,
      resetPriceSlider,
      // Search Engine
      setQuerySearch,
      addToBodySearch,
      removeFromBodySearch,
      removeAllFromBodySearch,
      removeFromBodySearchFilter,
      addToQuerySearchArray,
      removeFromQuerySearchArray,
      // Sort By
      setSortBy,
      setSortByHaveImages,
    }),
    [
      state,
      getComercialProfileData,
      setBanner,
      initBio,
      // setBio,
      updateBio,
      setProducts,
      removeProduct,
      setVideos,
      removeVideo,
      // Catalog
      getCatalog,
      addMoreProductsToCatalog,
      // Filters
      filter,
      addBodyFilters,
      removeBodyFilters,
      resetBodyFilters,
      setPriceSliders,
      setFavorites,
      resetPriceSlider,
      // Search Engine
      setQuerySearch,
      addToBodySearch,
      removeFromBodySearch,
      removeAllFromBodySearch,
      removeFromBodySearchFilter,
      addToQuerySearchArray,
      removeFromQuerySearchArray,
      // Sort By
      setSortBy,
      setSortByHaveImages,
    ]
  );

  return (
    <CommercialProfileContext.Provider value={memoProvider}>
      {props.children}
    </CommercialProfileContext.Provider>
  );
}

export default memo(CommercialProfileProvider);
