declare global {
  interface Window {
    catalogAbortController: {
      controller: any;
      signal: any;
    };
    clientsAbortController: {
      controller: any;
      signal: any;
    };
  }
}
window.catalogAbortController = {
  controller: undefined,
  signal: undefined,
};
window.clientsAbortController = {
  controller: undefined,
  signal: undefined,
};

export function abortCatalogController() {
  if (window.catalogAbortController.controller) {
    window.catalogAbortController.controller.abort();
  }
  const newController = new AbortController();
  window.catalogAbortController.controller = newController;
  window.catalogAbortController.signal = newController.signal;
  return newController.signal;
}

export function abortClientsController() {
  if (window.clientsAbortController.controller) {
    window.clientsAbortController.controller.abort();
  }
  const newController = new AbortController();
  window.clientsAbortController.controller = newController;
  window.clientsAbortController.signal = newController.signal;
  return newController.signal;
}
