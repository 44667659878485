import { IInitialGenericState } from 'interfaces/generics.interface';
import * as GenericType from './catalogGeneric.types';

export default function catalogGenericReducer(state: IInitialGenericState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case GenericType.SET_GENERICS:
      const setGenerics: IInitialGenericState = {
        ...state,
        generics: {
          ...state.generics,
          [payload.type]: payload.data,
        },
      };
      return setGenerics;

    case GenericType.SET_IS_LOADING_GENERICS:
      const setIsLoadingGenerics: IInitialGenericState = {
        ...state,
        isLoadingGenerics: payload,
      };
      return setIsLoadingGenerics;

    case GenericType.UPDATE_GENERIC:
      const updateGeneric: IInitialGenericState = {
        ...state,
        generics: {
          ...state.generics,
          [payload.type as keyof typeof state.generics]: state.generics[
            payload.type as keyof typeof state.generics
          ].map((generic) => {
            if (generic.group_id === payload.newGeneric.group_id) {
              return payload.newGeneric;
            }
            return generic;
          }),
        },
      };
      return updateGeneric;

    default:
      return state;
  }
}
