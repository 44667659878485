import * as ComercialInfoTypes from './comercialInfo.types';

export default function comercialInfoReducer(state: any, action: any) {
  const { type, payload } = action;

  switch (type) {
    case ComercialInfoTypes.GET_COMERCIAL_INFO:
      return {
        ...state,
        contacts: payload,
      };

    case ComercialInfoTypes.SELECT_COMERCIAL_CONTACT:
      return {
        ...state,
        contactSelected: payload,
      };

    case ComercialInfoTypes.CREATE_AGENT:
      return {
        ...state,
        contacts: [...state.contacts, payload],
      };

    case ComercialInfoTypes.UPDATE_AGENT:
      return {
        ...state,
        contacts: payload,
      };

    case ComercialInfoTypes.REMOVE_AGENT:
      return {
        ...state,
        contacts: payload,
      };

    default:
      return state;
  }
}
