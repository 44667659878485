// import logoLacoste from "assets/temporary_files/brands-logos/logo-Lacoste.png"
// import logoEcoalf from "assets/temporary_files/brands-logos/Ecoalf.jpg"
// import logoHub4Retail from "assets/img/logo192.png"

export type Notification = {
  id: string;
  summary: string;
  message: string;
  icon: string;
  read: boolean;
  complete?: string;
  abort?: string;
  details?: {
    type: string; // profile-wholesale
    id: string; // profileWholesaleId
  };
  createdAt: string;
  updatedAt: string;
};

export type Alert = {
  id: string;
  summary: string;
  message: string;
  read: boolean;
  icon: string;
  createdAt: string;
  updatedAt: string;
};

type InitialState = {
  notifications: Notification[];
  alerts: Alert[];
  markAsReaded: (type: string, notificationId: string, read: boolean) => void;
};

const initialNotificationsState: InitialState = {
  // notifications: [
  // 	{
  // 		id: "1",
  // 		summary: "¡Bienvenido a Hub4Retail! Comienza tu colaboración con tus clientes",
  // 		message: "¡Bienvenido a Hub4Retail! Comienza tu colaboración con tus clientes",
  // 		icon: logoHub4Retail,
  // 		read: false,
  // 		createdAt: '2023-10-31T13:32:39.825Z',
  // 		updatedAt: '2023-10-31T13:32:39.825Z'
  // 	},
  // 	{
  // 		id: "2",
  // 		summary: "<Wholesale> ha solicitado trabajar contigo",
  // 		message: "<Wholesale> ha solicitado trabajar contigo. ¡Comienza la colaboración!",
  // 		icon: logoLacoste,
  // 		read: false,
  // 		complete: 'url',
  // 		abort: 'url',
  // 		details: {
  // 			type: 'profile-wholesale',
  // 			id: "profile_id"
  // 		},
  // 		createdAt: '2023-10-30T12:32:39.825Z',
  // 		updatedAt: '2023-10-30T12:32:39.825Z'
  // 	},
  // 	{
  // 		id: "3",
  // 		summary: "<Wholesale> ha dejado de trabajar contigo",
  // 		message: "<Wholesale> ha dejado de trabajar contigo.",
  // 		icon: logoLacoste,
  // 		read: false,
  // 		createdAt: '2023-10-31T12:32:39.825Z',
  // 		updatedAt: '2023-10-31T12:32:39.825Z'
  // 	},
  // ],
  // alerts: [
  // 	{
  // 		id: '1',
  // 		summary: "<punto de venta> lleva 48 horas sin recibir datos",
  // 		message: "<punto de venta> lleva 48 horas sin recibir datos",
  // 		read: false,
  // 		icon: logoEcoalf,
  // 		createdAt: '2023-10-31T12:32:39.825Z',
  // 		updatedAt: '2023-10-31T12:32:39.825Z'
  // 	}
  // ],
  notifications: [],
  alerts: [],
  markAsReaded: (type: string, notificationId: string, read: boolean) => {},
};

export default initialNotificationsState;
