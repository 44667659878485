import { useAuth0 } from '@auth0/auth0-react';
import useContextUser from 'hooks/contexts/useContextUser';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { superAdminRole } from 'utils/constants';
import { home } from './paths';

export const LoginRequired = ({ children }: { children: React.ReactElement }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // navigate('/authentication/signup')
      loginWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  return children;
};

export const SuperAdminRequired = ({ children }: { children: React.ReactElement }) => {
  const { dbUser } = useContextUser();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    if (isMounted && dbUser.role !== superAdminRole) {
      navigate(home);
    }
    return () => {
      isMounted = false;
    };
  }, [dbUser.role, navigate]);

  return children;
};
