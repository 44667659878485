import { mediaDefaultLimit } from '../initialMultimediaState';
import * as MultimediaTypes from './multimedia.types';

export default function multimediaReducer(state: any, action: any) {
  const { type, payload } = action;

  switch (type) {
    case MultimediaTypes.GET_MULTIMEDIA:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          items: state[payload.type].items.concat(payload.items),
          bodyMultimediaFetch: {
            ...state[payload.type].bodyMultimediaFetch,
            options: {
              ...state[payload.type].bodyMultimediaFetch.options,
              index:
                state[payload.type].bodyMultimediaFetch.options.index +
                payload.items.length,
            },
          },
          thereAreMoreMedia: payload.items.length < mediaDefaultLimit ? false : true,
        },
      };

    case MultimediaTypes.SET_IS_LOADING_MEDIA:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          isLoading: payload.isLoading,
        },
      };

    case MultimediaTypes.SET_IS_LOADING_MORE_MEDIA:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          isLoadingMoreMedia: payload.isLoading,
        },
      };

    case MultimediaTypes.CREATE_MULTIMEDIA:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          items: [payload.item, ...state[payload.type].items],
        },
      };

    case MultimediaTypes.UPDATE_MULTIMEDIA:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          items: state[payload.type].items.map((item: any) =>
            item.id === payload.item.id ? payload.item : item
          ),
          search: {
            ...state[payload.type].search,
            media: state[payload.type].search.media.map((item: any) =>
              item.id === payload.item.id ? payload.item : item
            ),
          },
        },
      };

    case MultimediaTypes.SET_IS_LOADING_CREATE_MULTIMEDIA:
      return {
        ...state,
        isLoadingCreateMultimedia: payload,
      };

    case MultimediaTypes.SEARCH_MEDIA:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          search: {
            ...state.search,
            media: payload.media,
            query: payload.query,
          },
          thereAreMoreMedia:
            payload.media.length < mediaDefaultLimit && payload.query.length > 0
              ? false
              : true,
          bodyMultimediaFetch: {
            ...state[payload.type].bodyMultimediaFetch,
            options: {
              ...state[payload.type].bodyMultimediaFetch.options,
              index:
                payload.query.length === 0
                  ? state[payload.type].items.length
                  : state[payload.type].bodyMultimediaFetch.options.index +
                    payload.media.length,
            },
          },
        },
      };

    case MultimediaTypes.RESET_MEDIA:
      return {
        ...state,
        image: {
          ...state.image,
          items: [],
          bodyMultimediaFetch: {
            ...state.image.bodyMultimediaFetch,
            options: {
              ...state.image.bodyMultimediaFetch.options,
              index: 0,
            },
          },
          thereAreMoreMedia: true,
        },
        video: {
          ...state.video,
          items: [],
          bodyMultimediaFetch: {
            ...state.video.bodyMultimediaFetch,
            options: {
              ...state.video.bodyMultimediaFetch.options,
              index: 0,
            },
          },
          thereAreMoreMedia: true,
        },
        banner: {
          ...state.banner,
          items: [],
          bodyMultimediaFetch: {
            ...state.banner.bodyMultimediaFetch,
            options: {
              ...state.banner.bodyMultimediaFetch.options,
              index: 0,
            },
          },
          thereAreMoreMedia: true,
        },
      };

    case MultimediaTypes.SET_ITEM_SELECTED:
      return {
        ...state,
        itemSelected: payload,
      };

    case MultimediaTypes.SET_IS_OPEN_EDIT_MODAL:
      return {
        ...state,
        isOpenEditModal: payload,
      };

    case MultimediaTypes.REMOVE_MULTIMEDIA:
      return {
        ...state,
        [payload.type]: {
          ...state[payload.type],
          items: state[payload.type].items.filter((item: any) => item.id !== payload.id),
        },
      };

    default:
      return state;
  }
}
