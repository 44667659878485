import { memo, useCallback, useState } from 'react';
import { ModalsContext } from './ModalsContext';
import { ChildrenProps } from 'types';

const ModalsProvider = ({ children }: ChildrenProps) => {
  const [showAssociationModal, setShowAssociationModalAction] = useState(false);

  const setShowAssociationModal = useCallback((isOpen: boolean) => {
    setShowAssociationModalAction(isOpen);
  }, []);

  return (
    <ModalsContext.Provider
      value={{
        showAssociationModal,
        setShowAssociationModal,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};

export default memo(ModalsProvider);
